import React from "react";
import i18next from 'i18next';
import { Form as Formio, Components as FormioComponents } from 'react-formio';
import FormioUtils from 'formiojs/utils';
import StickyBox from "react-sticky-box";
import { Component, Form, Custom } from '../../../internal';
import components from "../custom";
import ViewDefinitionService from '../../../../Services/ViewDefinitionService';
import ViewService from "../../../../Services/ViewService";
import DataUtil from "../../../../../Utils/DataUtil";
import ServiceUtil from "../../../../../Utils/ServiceUtil";
import UiUtil from "../../../../../Utils/UiUtil";
import { ViewModal } from "../../../../../HRDPages/Common/ViewModal";

import { German } from 'flatpickr/dist/l10n/de';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { French } from 'flatpickr/dist/l10n/fr';
import { Italian } from 'flatpickr/dist/l10n/it';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { Russian } from 'flatpickr/dist/l10n/ru';
import { Japanese } from 'flatpickr/dist/l10n/ja';

import $ from 'jquery';

import "formiojs/dist/formio.full.min.css";
import "./FormioRenderer.css";
import { getUserConfig } from "../../../../../Services/Interceptors/Token";

import moment from 'moment';

export class FormioRenderer extends Component {

    constructor(props) {
        super(props);
        if (props.config)
            this.config = { ...this.config, ...props.config };
        window.UiUtil = UiUtil;
        window.DataUtil = DataUtil;
        FormioComponents.setComponents(components);
    };

    // config
    config = {
        debounceTime: 1000
    };

    // map to track currency fields - which can get updated via user event
    currencyFields = {};

    // map to track dependent dropdown parents
    parentDropdowns = {};

    // variable to track last changed field for an edit grid
    changedEditGridField = null;

    state = {
        cachedSelectData: {}
    };

    getButtonGroup = () => {
      return this.props.component?.properties?.buttonGroup || 'GRP_BUTTON';
    };

    generateSchema = (root) => {
        let schema = { display: 'form', components: [] };

        this.buildComponents(schema, root);

        return schema;
    };

    buildComponents = (formioSchema, parent) => {
        parent?.elements.map((element) => {
            let formioComponent = this.buildComponent(element, parent);
            if (formioComponent) {
                formioSchema.components.push(formioComponent);
                if (element?.elements?.length)
                    this.buildComponents(formioComponent, element);
            }
        });
    };

    buildComponent = (element, parent) => {
        //console.log('buildComponent element: ', element);
        switch (element?.type) {
            //case 'FORM':
            //    return { display: 'form', components: [] };
            case 'SECTIONS':
                return this.getSectionsComponent(element);
            case 'SECTION':
                return this.getSectionComponent(element);
            case 'FIELDGROUP':
                return this.getFieldGroupComponent(element);
            case 'EDITGRID':
                return this.getEditGridComponent(element);
            case 'DATAGRID':
                return this.getDataGridComponent(element);
            case 'INPUT':
                return this.getInputComponent(element, parent);
            case 'NUMBER':
                return this.getNumberComponent(element);
            case 'CURRENCY':
                return this.getCurrencyComponent(element, parent);
            case 'EMAIL':
                return this.getEmailComponent(element);
            case 'CHECKBOX':
                return this.getCheckboxComponent(element);
            case 'RADIO':
                return this.getRadioComponent(element);
            case 'SELECT':
                return this.getSelectComponent(element);
            case 'BUTTON':
                return this.getButtonComponent(element);
            case 'TEXTAREA':
                return this.getTextAreaComponent(element);
            case 'DATE':
                return this.getDateComponent(element, parent);
            // custom components start here
            case 'SEARCH':
                return this.getSearchPopupComponent(element);
            case 'FILE':
                return this.getFileComponent(element);
            // custom components end here
        }

        return null;
    };

    getToolBox = (element, appendLabel) => {

        let str = "";

        if (appendLabel && element?.properties?.label)
            str += element?.properties?.label;

        if (element?.properties?.toolbox) {

            if (str != "")
                str += '';

            str += ` <i data-info-title="${element?.properties?.label}" data-info-view="${element?.properties?.toolbox}" data-info-prevent-default="true" class="fa fa-info-circle text-muted pointer"></i>`
        }

        return str;
    };

    getSectionsComponent = (element) => {
        const component = {
            "_element": element,
            "type": this.isTabs() ? "tabs" : "", // tabs are not yet tested
            "input": false,
            "label": this.getToolBox(element, true),
            "_elements": element.elements,
            "hidden": element?.properties?.hidden == true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false,
            "components": []
        };
        return this.applyConditions(component, element);
    };

    getSectionComponent = (element) => {
        const component = {
            "_element": element,
            "type": "fieldset",
            "customClass": (element?.properties?.cssClassName != '') ? element.properties.cssClassName : '',
            "input": false,
            "title": element?.properties?.label,
            //"legend": element?.properties?.label,
            "label": "",//element?.properties?.label,
            "collapsible": false,
            "hidden": element?.properties?.hidden == true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false,
            "id": element?.code,
            "components": []
        };
        return this.applyConditions(component, element);
    };

    getFieldGroupComponent = (element) => {
        const component = {
            "_element": element,
            "type": "fieldset",
            "customClass": "formio-fieldset",
            "input": false,
            "legend": i18next.t("form:"+element?.code) != element?.code ? i18next.t("form:"+element?.code) : element?.properties?.label,
            "tooltip": element?.properties?.tooltip,
            "hidden": element?.properties?.hidden == true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false,
            "collapsible": false,
            "id": element?.code,
            "key": `${element.code}-fieldset`, //DO NOT PUT A KEY FOR fieldset as the edit grid might also have the same key
            "components": []
        };
        return this.applyConditions(component, element);
    };

    getEditGridComponent = (element) => {
        const component = {
            "_element": element,
            label: '',//element?.properties?.label,
            key: element.code,
            type: 'editgrid',
            input: true,
            hidden: element?.properties?.hidden == true,
            customConditional: element?.properties?.show ? element?.properties?.show : "",
            clearOnHide: false,
            disabled: element?.properties?.readonly ? element?.properties?.readonly : false,
            addAnother: element?.properties?.addLabel ? element?.properties?.addLabel : i18next.t("form:addAnother"),
            validate: element?.properties?.minLength ? {
                minLength: element?.properties?.minLength
            } : { },
            conditionalAddButton: 'show = instance.editRows.length == instance.editRows.filter(r => r.state == "saved").length;',
            templates: {
                header:
                    `<div class="row">
                        {% util.eachComponent(components, function(component, componentKey, componentRow) { %}
                            {%
                                if (displayValue(component) && component.type != 'hidden') {
                                    const totalCols = components.filter(c => c.type != 'hidden').length;
                                    const colSize = UiUtil.gridColSize(totalCols, componentKey==componentRow[0].key, !instance.options.readOnly && !instance.disabled ? 2 : 0);
                            %}
                                <div class="text-600 col-sm-{{colSize}}">{{ t(component.label) }}</div>
                            {%  } %}
                        {% }) %}
                        {% if (!instance.options.readOnly && !instance.disabled) { %}
                            <div class="col-sm-2"></div>
                        {% } %}
                    </div>`,
                row:
                    `<div class="row">
                        {% util.eachComponent(components, function(component, componentKey, componentRow) { %}
                            {%
                                if (displayValue(component) && component.type != 'hidden') {
                                    const totalCols = components.filter(c => c.type != 'hidden').length;
                                    const colSize = UiUtil.gridColSize(totalCols, componentKey==componentRow[0].key, !instance.options.readOnly && !instance.disabled ? 2 : 0);
                                    //console.log(components, component, row, component.key, row[component.key], getView(component, row[component.key]), getView)
                            %}
                                <div class="col-sm-{{colSize}}">
                                    {{ isVisibleInRow(component) && row[component.key]!=null ? getView(component, row[component.key]) : '-'}}
                                </div>
                            {% } %}
                        {% }) %}
                        {% if (!instance.options.readOnly && !instance.disabled && instance.editRows.length == instance.editRows.filter(r => r.state == "saved").length) { %}
                            <div class="col-sm-2">
                                <div class="btn-group pull-right">
                                    <button class="btn btn-default btn-light btn-sm editRow mr-2"><i class="{{ iconClass('edit') }}"></i></button>
                                {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}
                                    <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass('trash') }}"></i></button>
                                {% } %}
                                </div>
                            </div>
                        {% } %}
                    </div>`,
                footer: ''
            },
            components: []
        };
        return this.applyConditions(component, element);
    };

    getDataGridComponent = (element) => {
        const component = {
            "_element": element,
            label: '',//element?.properties?.label,
            key: element.code,
            type: 'datagrid',
            input: true,
            addAnother: element?.properties?.addLabel ? element?.properties?.addLabel : null,
            components: []
        };
        return this.applyConditions(component, element);
    };

    getInputComponent = (element, parent) => {
        let component;
        if (element?.properties?.hidden == true)
            // we still need to display the field but hide it via css 
            // otherwise we cannot set it's value programatically
            component = {
                "_element": element,
                "_initialValue": element?.properties?.value,
                "type": "hidden",
                //"type": "textfield",
                "key": element.code,
                //"defaultValue": element?.properties?.value,
                //"clearOnHide": false,
                "input": true,
                //"className": "d-none"
            };
        else
            component = {
                "_element": element,
                "_initialValue": element?.properties?.value,
                "type": "textfield",
                "label": this.getToolBox(element, true),
                "placeholder": element?.properties?.placeholder,
                "description": element?.properties?.help,
                "tooltip": element?.properties?.tooltip,
                "validate": {
                    "required": element?.properties?.required || false,
                    "maxLength": element?.properties?.length
                },
                "key": element.code,
                //"defaultValue": element?.properties?.value,
                "clearOnHide": parent?.type == 'EDITGRID' ? false : null,
                "input": true,
                "inputMask": element?.properties?.inputMask ? element?.properties?.inputMask : "",
                "customConditional": element?.properties?.show ? element?.properties?.show : "",
                "disabled": element?.properties?.readonly ? element?.properties?.readonly : false,
                "calculateValue": element?.properties?.calculateValue ? element?.properties?.calculateValue : ""
            };
        return this.applyConditions(component, element);
    };

    getNumberComponent = (element) => {
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "type": "number",
            "delimiter": true,
            "requireDecimal": false,
            "inputFormat": "plain",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false,
                "min": element?.properties?.minimum,
                "max": element?.properties?.maximum
            },
            "key": element.code,
            //"defaultValue": element?.properties?.value,
            "clearOnHide": false,
            "input": true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false
        };
        return this.applyConditions(component, element);
    };

    getCurrencyComponent = (element, parent) => {
        const data = this.props.component?.data?.data || {};
        const currencyFieldName = element?.properties?.currency;
        const currency = data[currencyFieldName] || data[parent.code]?.[0]?.[currencyFieldName];
        const currencyField = element?.properties?.currencyField;
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "type": "number",
            "delimiter": true,
            "requireDecimal": true,
            "decimalLimit": DataUtil.getDecimalPrecision(currency),
            "inputFormat": "plain",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "suffix": currency,
            "validate": {
                "required": element?.properties?.required || false,
                "min": element?.properties?.minimum,
                "max": element?.properties?.maximum
            },
            "key": element.code,
            //"defaultValue": element?.properties?.value,
            "clearOnHide": false,
            "input": true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false
        };
        if (currencyFieldName) {
            if (!this.currencyFields[currencyFieldName])
                this.currencyFields[currencyFieldName] = [];
            this.currencyFields[currencyFieldName].push(element.code);
        }
        if (currencyField) {
            component.redrawOn = currencyField;
            component.calculateValue =
                `const currency = data[component.redrawOn];
            const decimalLimit = DataUtil.getDecimalPrecision(currency);
            component.suffix = currency; 
            component.decimalLimit = decimalLimit; 
            component.requireDecimal = decimalLimit > 0; 
            instance.component.suffix = currency; 
            instance.component.decimalLimit = decimalLimit; 
            instance.component.requireDecimal = decimalLimit > 0; 
            instance.originalComponent.suffix = currency; 
            instance.originalComponent.decimalLimit = decimalLimit; 
            instance.originalComponent.requireDecimal = decimalLimit > 0; 
            instance.numberMask = instance.createNumberMask();
            instance.redraw();
            if(value != null && value.toFixed != null) { value = parseFloat(value.toFixed(decimalLimit), 10); }`;
        }
        return this.applyConditions(component, element);
    };

    getEmailComponent = (element) => {
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "type": "email",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false
            },
            "key": element.code,
            //"defaultValue": element?.properties?.value,
            "clearOnHide": false,
            "input": true,
            "inputMask": element?.properties?.inputMask ? element?.properties?.inputMask : "",
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false,
            "calculateValue": element?.properties?.calculateValue ? element?.properties?.calculateValue : ""
        };
        return this.applyConditions(component, element);
    };

    getCheckboxComponent = (element) => {
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "type": "checkbox",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false
            },
            "key": element.code,
            //"defaultValue": element?.properties?.value,
            "clearOnHide": false,
            "input": true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false,
            //"allowCalculateOverride": true, // This allows the calculated value to run any time the first field is changed and yet allow users to override the value. 
            "calculateValue": element?.properties?.calculateValue ? element?.properties?.calculateValue : ""
        };
        return this.applyConditions(component, element);
    };

    getRadioComponent = (element) => {
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "type": "radio",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false,
                "onlyAvailableItems": element?.properties?.required || false // when radio is required but initial value comes back as null, formio sets it as "Null" and validation pass when it should not - this property insures that the radio value is one of the available options
            },
            "key": element.code,
            //"defaultValue": element?.properties?.value,
            "values": element?.properties?.values || [],
            "clearOnHide": false,
            "dataType": "string",
            "input": true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false
        };
        return this.applyConditions(component, element);
    };

    getSelectComponent = (element) => {
        if (Array.isArray(element?.properties?.refreshOnChange)) {
            const parents = element?.properties?.refreshOnChange;
            const parentDropdowns = this.parentDropdowns;
            parents.map(p => {
                if (!parentDropdowns[p])
                    parentDropdowns[p] = [];
                parentDropdowns[p].push(element.code);
            });
        }
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "type": "select",
            "widget": "choicesjs",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false
            },
            "key": element.code,
            //"defaultValue": element?.properties?.value,
            "valueProperty": "value",
            "data": {
                "values": element?.properties?.values || []
            },
            "clearOnHide": false, //rj - added 12/11/2023
            "dataType": "string",
            "input": true,
            "customOptions": {
                "fuseOptions": { // added this to fix filtering issue (i.e. reason value 56)
                    "tokenize": true,
                    "matchAllTokens": true,
                    "ignoreLocation": true,
                    "keys": ['label']
                }
            },
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false,
            "hidden": element?.properties?.hidden == true
        };
        const itemTemplate = element?.properties?.itemTemplate;
        if (itemTemplate)
            component.template = "<span>{{ " + itemTemplate + " }}</span>";
        else if (element?.properties?.concatenateKey)
            component.template = "<span>{{ item.label }} ({{ item.value }})</span>";
        return this.applyConditions(component, element);
    };

    getButtonComponent = (element) => {
        const component = {
            "_element": element,
            "type": "button",
            "action": "event",
            "event": element?.properties?.event,
            "eventUrl": element?.properties?.eventUrl,
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "leftIcon": DataUtil.getButtonIcon(element?.code),
            /*"validate": {
                "required": element?.properties?.required
            },*/
            //"showValidations": true,
            //"disableOnInvalid": true,
            "id": element?.code,
            "key": element.code,
            //"input": true,
            "hidden": element?.properties?.hidden == true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false
        };
        return this.applyConditions(component, element);
    };

    getTextAreaComponent = (element) => {
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "type": "textarea",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false,
                "maxLength": element?.properties?.length
            },
            "key": element.code,
            //"defaultValue": element?.properties?.value,
            "clearOnHide": false, //rj - added 12/11/2023
            "input": true,
            "hidden": element?.properties?.hidden == true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false
        };
        return this.applyConditions(component, element);
    };

    getDateComponent = (element, parent) => {
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "_serverFormat": process.env.REACT_APP_SERVICE_DATE_FORMAT,
            "_parent": parent,
            //"_conditionalDate": conditionalDate,
            "type": "datetime",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false
            },
            "format": element?.properties?.format || DataUtil.getUserFormat().date,
            "tableView": true,
            "enableMinDateInput": false,
            "datePicker": {
                "disableWeekends": false,
                "disableWeekdays": false
            },
            "enableMaxDateInput": false,
            "enableTime": false,
            "key": element.code,
            //"defaultDate": element?.properties?.value,
            "clearOnHide": false,
            "input": true,
            "hidden": element?.properties?.hidden == true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false,
            //"calculateValue": "value = value ? moment(value).format(component._serverFormat) : value",
            "customOptions": {
                "___dateFormat": process.env.REACT_APP_SERVICE_DATE_FORMAT,
                "locale": this.getDateLocale()
            },
            "widget": {
                "type": "calendar",
                "displayInTimezone": "viewer",
                //"language": "en",
                //"useLocaleSettings": false,
                "allowInput": true,
                "mode": "single",
                "enableTime": false,
                "noCalendar": false,
                "format": element?.properties?.format || DataUtil.getUserFormat().date,
                "hourIncrement": 1,
                "minuteIncrement": 1,
                "time_24hr": false,
                "minDate": null,
                "disableWeekends": false,
                "disableWeekdays": false,
                "maxDate": null,
                "___dateFormat": process.env.REACT_APP_SERVICE_DATE_FORMAT
            }
        };

        return this.applyConditions(component, element);

        /*const conditionalHidden = element.properties?.conditionalHidden != null && element.properties?.conditionalHidden != '';
        const conditionalDisabled = element.properties?.conditionalDisabled != null && element.properties?.conditionalDisabled != '';
        const conditionalRequired = element.properties?.conditionalRequired != null && element.properties?.conditionalRequired != '';
        const parentConditionalHidden = parent.properties?.conditionalHidden != null && parent.properties?.conditionalHidden != '';
        const parentConditionalDisabled = parent.properties?.conditionalDisabled != null && parent.properties?.conditionalDisabled != '';
        const parentConditionalRequired = parent.properties?.conditionalRequired != null && parent.properties?.conditionalRequired != '';

        if (parentConditionalHidden && !conditionalHidden)
            element.properties.conditionalHidden = parent.properties.conditionalHidden;
        if (parentConditionalDisabled && !conditionalDisabled)
            element.properties.conditionalDisabled = parent.properties.conditionalDisabled;
        if (parentConditionalRequired && !conditionalRequired)
            element.properties.conditionalRequired = parent.properties.conditionalRequired;

        if (conditionalDisabled)
            this.applyDisabledCondition(component, element);
        if (conditionalRequired)
            this.applyRequiredCondition(component, element);

        const componentWrapper = {
            "_type": "dateWrapper",
            "_key": element.code,
            "columns": [{
                "components": [component],
                "width": 12,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "size": "xl",
                "currentWidth": 12
            }],
            "type": "columns",
            "input": false,
            "tableView": true
        };

        return this.applyConditions(componentWrapper, element);*/
    };

    getDateLocale = () => {
        const userConfig = getUserConfig();
        const language = userConfig?.filter((config) => config.key == 'language')?.[0]?.value || 'en-US';
        const locale = language.split('-')[0];

        switch (locale) {
            case 'de':
                return German;
            case 'es':
                return Spanish;
            case 'fr':
                return French;
            case 'it':
                return Italian;
            case 'nl':
                return Dutch;
            case 'pt':
                return Portuguese;
            case 'ru':
                return Russian;
            case 'jp':
                return Japanese;
        }
        return null; // default = english
    };

    getSearchPopupComponent = (element) => {
        const component = {
            "_element": element,
            "_initialValue": element?.properties?.value,
            "type": "searchPopupCustomComp",
            "label": this.getToolBox(element, true),
            "placeholder": element?.properties?.placeholder,
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false
            },
            "key": element.code,
            //"defaultValue": element?.properties?.value,
            "input": true,
            "clearOnHide": false, //rj - added 12/11/2023
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false
        };
        return this.applyConditions(component, element);
    };

    getFileComponent = (element) => {
        //console.log(element)
        const component = {
            "_element": element,
            "_formElement": this.props.component?.elements?.[0],
            "type": "fileUploadCustomComp",
            "label": this.getToolBox(element, true),
            "description": element?.properties?.help,
            "tooltip": element?.properties?.tooltip,
            "validate": {
                "required": element?.properties?.required || false
            },
            "key": element.code,
            "input": true,
            "hidden": element?.properties?.hidden == true,
            "customConditional": element?.properties?.show ? element?.properties?.show : "",
            "disabled": element?.properties?.readonly ? element?.properties?.readonly : false
        };
        return this.applyConditions(component, element);
    };

    applyConditions = (component, element) => {
        this.applyHiddenCondition(component, element);
        this.applyDisabledCondition(component, element);
        this.applyRequiredCondition(component, element);
        return component;
    };

    applyHiddenCondition = (component, element) => {
        const logic = component.logic || [];
        if (element.properties?.conditionalHidden) {
            logic.push({
                "name": element.code + " Hidden Logic",
                "trigger": {
                    "type": "javascript",
                    "javascript": component?._type ? element.properties?.conditionalHidden + '; setTimeout(function(){ try { instance.getComponent(component._key).setValue(data[component._key]); } catch(e) {} }, 100);' : element.properties?.conditionalHidden
                },
                "actions": [
                    {
                        "name": element.code + " Hidden Action",
                        "type": "property",
                        "property": {
                            "label": "Hidden",
                            "value": "hidden",
                            "type": "boolean"
                        },
                        "state": true
                    }
                ]
            });
        }
        if (logic.length)
            component.logic = logic;
    };

    applyDisabledCondition = (component, element) => {
        const logic = component.logic || [];
        if (element.properties?.conditionalDisabled) {
            logic.push({
                "name": element.code + " Disabled Logic",
                "trigger": {
                    "type": "javascript",
                    "javascript": component?._type ? element.properties?.conditionalDisabled + '; setTimeout(function(){ try { instance.getComponent(component._key).setValue(data[component._key]); } catch(e) {} }, 100);' : element.properties?.conditionalDisabled
                },
                "actions": [
                    {
                        "name": element.code + " Disabled Action",
                        "type": "property",
                        "property": {
                            "label": "Disabled",
                            "value": "disabled",
                            "type": "boolean"
                        },
                        "state": true
                    }
                ]
            });
        }
        if (logic.length)
            component.logic = logic;
    };

    applyRequiredCondition = (component, element) => {
        const logic = component.logic || [];
        if (element.properties?.conditionalRequired) {
            logic.push({
                "name": element.code + " Required Logic",
                "trigger": {
                    "type": "javascript",
                    "javascript": component?._type ? element.properties?.conditionalRequired + '; setTimeout(function(){ try { instance.getComponent(component._key).setValue(data[component._key]); } catch(e) {} }, 100);' : element.properties?.conditionalRequired
                },
                "actions": [
                    {
                        "name": element.code + " Required Action",
                        "type": "property",
                        "property": {
                            "label": "Required",
                            "value": "validate.required",
                            "type": "boolean"
                        },
                        "state": true
                    }
                ]
            });
        }
        if (logic.length)
            component.logic = logic;
    };

    isAffix = () => {
        const renderType = this.props.component?.properties?.renderType;
        return renderType == 'affix';
    };

    isTabs = () => {
        const renderType = this.props.component?.properties?.renderType;
        return renderType == 'tabs';
    };

    updateAffix = () => {
        const groups = $('.formio-fieldset').not('.formio-hidden');
        $('.form-navigation').find('.nav-item').hide();

        $.each(groups, (index, group) => {
            const $group = $(group);
            const groupId = $group.attr('id');
            $(`#affix-nav-item-${groupId}`).show();
        });

        $('[data-spy="scroll"]').each(function () {
            $(this).scrollspy('refresh');
        });
    };

    getFormio = () => {
        return this.formioFormRef;
    };

    showComponent = async (key) => {
        const formio = this.formioFormRef.formio;
        const component = formio.getComponent(key);
        try {
            component.component.hidden = false;
            component.visible = true;
        }
        catch (e) {
            console.log('Failed to show: ' + key, component)
        }
    };

    hideComponent = async (key) => {
        const formio = this.formioFormRef.formio;
        const component = formio.getComponent(key);
        try {
            component.component.hidden = true;
            component.visible = false;
        }
        catch (e) {
            console.log('Failed to hide: ' + key, component)
        }
    };

    enableComponent = async (key) => {
        const formio = this.formioFormRef.formio;
        const component = formio.getComponent(key) || formio.getComponentById(key);
        try {
            if (component._disabled) {
                component.component.disabled = false;
                component.disabled = false;
                component._disabled = false;
                component.redraw();
            }
        }
        catch (e) {
            console.log('Failed to enable: ' + key, component)
        }
    };

    disableComponent = async (key) => {
        const formio = this.formioFormRef.formio;
        const component = formio.getComponent(key) || formio.getComponentById(key);
        try {
            if (!component._disabled) {
                component.component.disabled = true;
                component.disabled = true;
                component._disabled = true;
                component.redraw();
            }
        }
        catch (e) {
            console.log('Failed to disable: ' + key, component)
        }
    };

    /*onSubmit = async (e) => {
        const eventUrl = e.component.eventUrl;
        const data = DataUtil.removeKeyStartsWith({ ...e.data }, 'BTN_');
        const params = this.getParams();
        const valid = (this.props.preButtonEvent) ? this.props.preButtonEvent(e, data, params) : true;
        console.log(this.formioFormRef.formio.checkValidity);
        this.formioFormRef.formio.setPristine(false);
        this.formioFormRef.formio.isValid();
        if(valid) {
            const response = await new ViewDefinitionService().saveViewData(eventUrl, { data: data, params: params }).catch((error) => LogUtil.error(error));
            console.log(response);
            return response;
        }
    };*/

    onCustomEventTrigger = (component) => {
        return this.onCustomEvent({ component: component.component, data: component._data, type: component.component.event });
    };

    onCustomEvent = async (e) => {
        const formio = this.formioFormRef.formio;
        const rawData = DataUtil.clone({ /*...this.props.component.data.data,*/ ...e.data });
        const data = DataUtil.removeKeyStartsWith(rawData, 'BTN_');
        const formProperties = this.props.component?.elements?.[0]?.properties;
        const params = this.getParams();

        formio.setPristine(false);
        formio.isValid();

        const formValid = formio.checkValidity();
        const preValidationEventValid = (this.props.preButtonValidationEvent) ? this.props.preButtonValidationEvent({ event: e, formValid, params }) : true;

        //console.log(e, formValid, preValidationEventValid, formio);

        if (formValid || preValidationEventValid) {
            const eventUrl = { url: e.component.eventUrl };
            const preButtonEventValid = (this.props.preButtonEvent) ? this.props.preButtonEvent(e, data, params, formProperties, eventUrl) : true;

            if (preButtonEventValid) {
                try {
                    UiUtil.showLoading(i18next.t("form:submitTitle"), i18next.t("form:submitDesc"));
                    const response = await new ViewDefinitionService().saveViewData(eventUrl.url, { data: data, params: params });
                    UiUtil.hideLoading();
                    if ((ServiceUtil.hasSuccess(response.data) || ServiceUtil.hasInfo(response.data)) && this.props.postButtonEvent)
                        this.props.postButtonEvent(e, data, params, response.data);
                    else
                        ServiceUtil.showMessages(i18next.t("form:submitErrorTitle"), response.data);
                }
                catch (e) {
                    console.log(e);
                    UiUtil.hideLoading();
                    UiUtil.showToast(i18next.t("form:submitFailedTitle"));
                }
            }
            else
                this.props.postButtonEvent(e, data, params, null);
        }
        else
            UiUtil.showToast(i18next.t("form:submitValidationMessage"));
    };

    onInitialized = async (e) => {
        // formio has an issue with masked inputs getting validated with onload data
        // the change below has a fix for it
        if (this.formioFormRef?.formio)
            this.formioFormRef.formio.validator.validators.mask.check = function (component, setting, value) {
                var inputMask;

                if (component.isMultipleMasksField) {
                    var maskName = value ? value.maskName : undefined;
                    var formioInputMask = component.getMaskByName(maskName);

                    if (formioInputMask) {
                        inputMask = formioInputMask;
                    }

                    value = value ? value.value : value;
                } else {
                    inputMask = setting;
                }

                inputMask = inputMask ? (0, FormioUtils.getInputMask)(inputMask) : null;

                if (value && inputMask && !component.skipMaskValidation) {
                    // If char which is used inside mask placeholder was used in the mask, replace it with space to prevent errors
                    inputMask = inputMask.map(function (char) {
                        return char === component.placeholderChar ? ' ' : char;
                    });
                    //RJ - START
                    // commented line below
                    // return (0, _utils.matchInputMask)(value, inputMask);
                    // added line below
                    return (0, FormioUtils.matchInputMask)(value, inputMask) || (0, FormioUtils.matchInputMask)(component.getValue(), inputMask);
                    //RJ - END
                }

                return true;
            };
    };

    onChange = async (e) => {
        //console.log('4 onChange', e);
        const data = e.data;
        const params = this.getParams();
        const changed = e.changed;
        const instance = changed?.instance;
        const preOnChangeValid = (this.props.preOnChange) ? this.props.preOnChange({ data, params, instance }) : true;
        // rj - 10/29/2021: added below code to identify onChange being triggered onload
        if(changed?.flags?.fromSubmission)
            return false;
        // rj - X/X/2021 (this comment was before the below comments of 8/4/2021 & 8/5/2021): e.changed is populated only when a field is manually changed
        // rj - 8/4/2021: added "&& instance.visible" check not to have hidden fields trigger an event
        // rj - 8/5/2021: added "&& preOnChangeValid" check not to have form in display mode trigger an event
        if (changed && instance?.visible && preOnChangeValid) {
            const component = changed.component;
            const element = component._element;
            if (this.parentDropdowns[component.key] && component.type != 'hidden') // if "changed" is parent to a/many components, update its/their values
                this.triggerParentDropdownChange(changed);
            if (element.properties.event && element.properties.event != '' && element.properties.eventFields?.length > 0) {
                //const { field } = DataUtil.extractArrayField(data, component.key);
                // if field is from a list, do no trigger the event for it here
                // it should be triggered when the row is saved
                //if(!field) {
                if (changed.instance.inEditGrid == true) {
                    // we need to track which row in the edit grid got changed so we can mark it's ROW_CHANGED column (if exists)
                    this.changedEditGridField = changed;
                }
                else {
                    if (component.type == 'textfield' || component.type == 'number' || component.type == 'textarea' || component.type == 'datetime')
                        this.triggerEventDebounced(component, data, instance);
                    else
                        this.triggerEvent(component, data, instance);
                }
            }
            else if (component.type == 'editgrid' && component?.components?.length) {
                const data = e.data;
                component.components.map(c => {
                    // only 1 component in a given editgrid should have an event configured
                    // in some rare cases, we could have more than 1 component in the same editgrid
                    // with an event configured but those events must have mutually exclusive eventParams and eventFields
                    if (c.type == 'columns' && c._type == 'dateWrapper') {
                        if (c.columns[0].components[0]._element?.properties?.event != '')
                            this.triggerEvent(c.columns[0].components[0], data, instance);
                    }
                    else if (c._element.properties.event)
                        this.triggerEvent(c, data, instance);
                });
            }
            // the below code is no longer needed since we wrapped datetime components by a column component
            /*else if (component.type == 'datetime') {
                console.log(changed, instance, component);
                const conditionalHidden = component._element.properties.conditionalHidden;
                const conditionalRequired = component._element.properties.conditionalRequired;
                const conditionalDisabled = component._element.properties.conditionalDisabled;
                // formio has a bug with conditional dates where it sets the date to the selected date minus 1 day
                // if you focus and then blur without changing the selected date, it also sets the date to the selected date minus 1 day
                // the code below is the workaround for that - by setting the date manually to changed.value
                if(conditionalHidden != '' || conditionalRequired != '' || conditionalDisabled != '')
                    instance.setValue(changed.value);
            }*/
        }

        this.updateAffix();
    };

    triggerParentDropdownChange = async (changed) => {
        const formio = this.formioFormRef.formio;
        const data = formio._data;
        const parent = changed.component;
        const children = this.parentDropdowns[parent.key];
        //console.log('triggerParentDropdownChange', parent.key, children);
        // event is running, disable the form buttons
        this.disableComponent(this.getButtonGroup());
        children.map(async (childKey) => {
            const child = formio.getComponent(childKey);
            if (changed.value == '') {
                //console.log('>>>>>>> clear child', childKey, child);
                if (child.getValue() != child.emptyValue)
                    child.setValue(child.emptyValue);
                //child.component.data.values = [];
                //child.setItems([]);
                this.setSelectItems(child, []);
            }
            else {
                try {
                    this.setLoading(changed.instance);
                    const element = child.component._element;
                    const parentsKey = this.getParentsKey(element.properties.refreshOnChange, data);
                    //console.log(childKey, parentsKey, element.properties.refreshUrl);
                    const values = await this.getDependentValuesCall(childKey, parentsKey, element.properties.refreshUrl);
                    child.setValue(child.emptyValue);
                    this.setSelectItems(child, values);
                    //console.log('values', childKey, values);
                    this.removeLoading(changed.instance);
                }
                catch (e) {
                    this.removeLoading(changed.instance);
                }
            }
        });
        // event is finished running, enable the form buttons
        this.enableComponent(this.getButtonGroup());
    };

    setSelectItems = async (component, values) => {
        component.component.data.values = values;
        component.originalComponent.data.values = values;
        component.setItems(values);
    };

    getDependentValuesCall = async (key, parentsKey, refreshUrl) => {
        let cachedSelectData = this.state.cachedSelectData;
        let selectData = cachedSelectData[key];
        let values = [];

        if (selectData?.[parentsKey]?.valuesFetched == true) {
            values = selectData[parentsKey].values;
            //console.log('getDependentValuesCall() from CACHE - key: ' + key + ' - parentsKey: ' + parentsKey, ' - values: ', values);
        }
        else if (!selectData?.loading) {
            if (!selectData) {
                selectData = { loading: true };
                cachedSelectData[key] = selectData;
                this.setState({ cachedSelectData });
            }
            const params = this.getParams();
            parentsKey.split('~').map(parentKey => {
                const parentKeyArray = parentKey.split('=');
                params[parentKeyArray[0]] = parentKeyArray[1];
            });
            params['FieldName'] = key;
            const response = await new ViewService().getViewByUrl(refreshUrl, params);
            values = Array.isArray(response.data) ? response.data : [];
            selectData[parentsKey] = { values: values, valuesFetched: true };
            selectData.loading = false;
            cachedSelectData[key] = selectData;
            this.setState({ cachedSelectData });
            //console.log('getDependentValuesCall() - key: ' + key + ' - parentsKey: ' + parentsKey, ' - values: ', values);
        }
        return values;
    };

    getParams = () => {
        const params = this.props.params ? this.props.params : {};
        // clean up UI related static params
        delete params.PAGE_TITLE;
        delete params.PAGE_SUB_TITLE;
        delete params.PROFILE_VIEW;
        delete params.FORM_VIEW;
        return params;
    };

    getParentsKey = (refreshOnChange, data) => {
        return refreshOnChange.map(p => p + '=' + (data[p] || '')).join('~');
    };

    /*getParentsParams = (refreshOnChange, data) => {
        return refreshOnChange.map(p => p+'_'+(data[p]||'')).join('~');
    };*/

    // for inputs/texareas instead of calling triggerEvent, we should call triggerEventDebounced below
    triggerEvent = async (component, data, instance) => {
        const rawData = DataUtil.clone({ /*...this.props.component.data.data,*/ ...data });
        const element = component._element;
        const event = element.properties.event;
        const eventUrl = element.properties.eventUrl + '?v=false';
        const eventParams = element.properties.eventParams;
        const params = this.getParams();
        const eventData = {};
        const formProperties = this.props.component?.elements?.[0]?.properties;
        const changedEditGridField = instance.type == 'editgrid' ? this.changedEditGridField : null;

        eventParams.map(f => {
            const { key, field } = DataUtil.extractArrayField(rawData, f);
            if (field) // if field is from a list, return the whole list
                eventData[key] = field;
            else // otherwise, return the field
                eventData[f] = rawData[f];
        });

        const preEventValid = (this.props.preEvent) ? this.props.preEvent({ event, eventData, params, component, instance, formProperties, changedEditGridField }) : true;

        if (preEventValid) {
            try {
                this.setLoading(instance);
                // event is running, disable the form buttons
                this.disableComponent(this.getButtonGroup());
                const response = await new ViewDefinitionService().postViewData(eventUrl, { data: eventData, params: params });
                if (ServiceUtil.hasError(response.data))
                    ServiceUtil.showMessages(i18next.t("form:userEventErrorTitle"), response.data);
                else {
                    if (ServiceUtil.hasWarning(response.data))
                        ServiceUtil.showMessages(i18next.t("form:userEventWarningTitle"), response.data);
                    this.updateEventFields(component, response.data);
                    if (this.props.postEvent)
                        this.props.postEvent({ event, eventData, params, component, responseData: response.data });
                }
                this.removeLoading(instance);
                // event is finished running, enable the form buttons
                this.enableComponent(this.getButtonGroup());
            }
            catch (e) {
                UiUtil.showToast(i18next.t("form:userEventFailedTitle"));
                this.removeLoading(instance);
            }
        }
    };

    // this is the debounced version of triggerEvent which should be called for inputs/texareas instead of 
    // calling triggerEvent directly to avoid multiple unwanted call when user types in an input
    triggerEventDebounced = ServiceUtil.debounce(this.triggerEvent, this.config.debounceTime);

    updateEventFields = async (component, data) => {
        const schema = this.formioFormRef.props.form;
        const formio = this.formioFormRef.formio;
        const submission = formio._submission;
        const element = component._element;
        const eventFields = element.properties.eventFields;
        // the eventFieldsTypeTable array is to track table field type
        const eventFieldsTypeTable = [];
        // the eventFieldsTypeInput array is to track other field types
        const eventFieldsTypeInput = [];
        const eventComponent = formio.getComponent(component.key);
        //console.log('updateEventFields', schema, component, data, submission);
        // rj - 11/29/2021 - cleanUp
        this.cleanUpData(schema, data.data);
        //console.log('updateEventFields > cleanUpData:', component, data, submission);

        // 1) update dropdowns first and track tables field types and then other field types
        eventFields?.map(f => {
            if (component.key != f) {
                const fComponent = formio.getComponent(f);
                if (fComponent) {
                    const { key, field } = DataUtil.extractArrayField(data.data, f);
                    if (field) { // if field is from a list, return the whole list
                        // if the field is a select, we need to update the options - we have this in Hire > WT
                        const kComponent = formio.getComponent(key);
                        if (fComponent.type == 'select') {
                            //console.log('updating table select: ', key, f, data.metadata.fields[f].values);
                            FormioUtils.getComponent(kComponent.component.components, f).data.values = data.metadata.fields[f].values;
                            FormioUtils.getComponent(kComponent.originalComponent.components, f).data.values = data.metadata.fields[f].values;
                            this.setSelectItems(fComponent, data.metadata.fields[f].values);
                            this.setSelectItems(kComponent.getComponent(f)[0], data.metadata.fields[f].values);
                            //console.log(kComponent.getComponent(f));
                        }
                        else {
                            if (!eventFieldsTypeTable.find(ef => ef.key == key))
                                eventFieldsTypeTable.push({ key, field, f });
                            // 1.b) update currency fields, if any
                            if (this.currencyFields[f]) {
                                // field value will be changed in step 2 (since this field will be part of eventFieldsTypeTable)
                                // but we need to change the currency and decimalLimit here
                                this.currencyFields[f].map(cf => {
                                    this.updateCurrencyDetails(cf, data.data[key]?.[0]?.[f]);
                                });
                            }
                        }
                    }
                    else { // otherwise, return the field
                        if (fComponent.type == 'select') {
                            //console.log('updating select: ', f, data.metadata.fields[f].values);
                            //fComponent.setValue(fComponent.emptyValue);
                            if (data.data[f] == null) {
                                if(eventComponent)
                                    eventComponent._data[f] = '';
                                submission.data[f] = '';
                            }
                            else {
                                if(eventComponent)
                                    eventComponent._data[f] = data.data[f];
                                submission.data[f] = data.data[f];
                            }
                            this.setSelectItems(fComponent, data.metadata.fields[f].values);
                            // 1.c) update currency fields, if any
                            if (this.currencyFields[f]) {
                                // field value will be changed in step 3 (since this field will be part of eventFieldsTypeInput)
                                // but we need to change the currency and decimalLimit here
                                this.currencyFields[f].map(cf => {
                                    this.updateCurrencyDetails(cf, data.data[f]);
                                });
                            }
                        }
                        else
                            eventFieldsTypeInput.push(f);
                    }
                }
            }
            else
                console.log('skipping: ', f, data.data[f]);
        });
        // 2) after we are done updating dropdowns, then we can update table field types
        eventFieldsTypeTable?.map(({ key, field, f }) => {
            if (component.key != f) {
                //console.log('>>>>>>>>>>> updating table: ', f, key, field);
                //eventComponent._data = data.data;
                if(eventComponent)
                    eventComponent._data[key] = field;
                submission.data[key] = field;
                //formio.getComponent(key).updateValue(field);
            }
        });
        // 3) after we are done updating tables and dropdowns, then we can update other field types
        eventFieldsTypeInput?.map(f => {
            if (component.key != f) {
                //console.log('updating field: ', f, data.data[f]);
                //eventComponent._data = data.data;
                // rj - 8/3/2021: added this if block because sometimes a user even blanks out fields (returns empty string)
                // but mdf is returning null and even if we set that field to null, formio does not clear the previous value
                if (data.data[f] == null) {
                    if(eventComponent)
                        eventComponent._data[f] = '';
                    submission.data[f] = '';
                }
                else {
                    const fComponent = formio.getComponent(f);
                    if (fComponent?.type == 'checkbox') {
                        if (data.data[f] == 'X') {
                            if(eventComponent)
                                eventComponent._data[f] = true;
                            submission.data[f] = true;
                        }
                        else {
                            if(eventComponent)
                                eventComponent._data[f] = false;
                            submission.data[f] = false;
                        }
                    }
                    else {
                        if(eventComponent)
                            eventComponent._data[f] = data.data[f];
                        submission.data[f] = data.data[f];
                    }
                }
                //formio.getComponent(f).updateValue(data.data[f]);
                // 3.b) update currency fields, if any
                if (this.currencyFields[f]) {
                    // field value will be changed in step 3 (since this field will be part of eventFieldsTypeInput)
                    // but we need to change the currency and decimalLimit here
                    this.currencyFields[f].map(cf => {
                        this.updateCurrencyDetails(cf, data.data[f]);
                    });
                }
            }
        });
        //console.log(submission);
        formio.setSubmission(submission);
    };

    isCurrencyComponent = (component) => {
        return component.component._element.type == 'CURRENCY';
    };

    updateCurrencyDetails = (cf, currency) => {
        const formio = this.formioFormRef.formio;
        const cfComponent = formio.getComponent(cf);
        if (cfComponent) {
            //const fcElement = cfComponent.component._element;
            const decimalLimit = DataUtil.getDecimalPrecision(currency);
            //cfComponent.suffix = currency;
            cfComponent.component.suffix = currency;
            cfComponent.originalComponent.suffix = currency;
            cfComponent.decimalLimit = decimalLimit;
            cfComponent.component.decimalLimit = decimalLimit;
            cfComponent.originalComponent.decimalLimit = decimalLimit;
            cfComponent.numberMask = cfComponent.createNumberMask();
            if(cfComponent.inEditGrid) {
                setTimeout(function() {
                    const cfParentComponent = formio.getComponent(cfComponent.parent.path);
                    const cfComponentSource = FormioUtils.getComponent(cfParentComponent.component.components, cf);
                    cfComponentSource.suffix = currency;
                    cfComponentSource.decimalLimit = decimalLimit;
                }, 1000);
            }
            cfComponent.redraw();
        }
    };

    setLoading = (component) => {
        if(component?.type == 'editgrid')
            component?.setLoading(component?.element?.getElementsByClassName('list-group-header')?.[0], true);
        else if(component?.type == 'checkbox')
            component?.setLoading(component?.element?.getElementsByClassName('form-check-label')?.[0], true);
        else
            component?.setLoading(component?.element?.getElementsByClassName('col-form-label')?.[0], true);
    };

    removeLoading = (component) => {
        if(component?.type == 'editgrid')
            component?.setLoading(component?.element?.getElementsByClassName('list-group-header')?.[0], false);
        else if(component?.type == 'checkbox')
            component?.setLoading(component?.element?.getElementsByClassName('form-check-label')?.[0], false);
        else
            component?.setLoading(component?.element?.getElementsByClassName('col-form-label')?.[0], false);
    };

    scrollTo = (e) => {
        e.preventDefault();
        const anchor = $(e.target);

        $('html, body').animate({
            scrollTop: parseInt($(anchor.attr('href')).offset().top) - 80
        }, 1000);
    };

    componentDidMount() {
        //console.log('componentDidMount', this.formioFormRef, this.props.component);
        const data = this.props.component?.data;
        if (ServiceUtil.hasWarning(data))
            ServiceUtil.showMessages(i18next.t("form:initialWarningTitle"), data);
        /*const schema = this.generateSchema(this.props.component);
        const submission = { data:  { ...this.props.component?.data?.data } };
        Formio.createForm(document.getElementById('rjform'), schema).then((formio) => {
            console.log(formio);
            this.formioFormRef = formio; window.fio = formio;
            / *const component = formio.getComponent('key');
          
            if (component) {
              component.on('focus', () => {
                  console.log('focus');
              });
              component.on('blur', () => {
                  console.log('blur');
              });
            }* /
          });*/
        /*const schema = this.generateSchema(this.props.component);
        const submission = { data:  { ...this.props.component?.data?.data } };
        ReactDOM.render(
            <FormReact form={schema} onSubmit={console.log} ref={ref => { this.formioFormRef = ref; window.fio = ref; }} 
            submission={submission}
            onFormLoad={(e) => {console.log('1 onFormLoad', e, this.formioFormRef) }} 
            onError={(e) => {console.log('2 onError', e, this.formioFormRef) }} 
            onRender={(e) => {console.log('3 onRender', e, this.formioFormRef) }} 
            onAttach={(e) => {console.log('5 onAttach', e, this.formioFormRef) }} 
            onBuild={(e) => {console.log('6 onBuild', e, this.formioFormRef) }} 
            onInitialized={(e) => {console.log('7 onInitialized', e, this.formioFormRef) }} 
            onAttach={(e) => {console.log('onAttach', e, this.formioFormRef) }} 
            onChange={(e) => { this.onChange(e) }} 
            onCustomEvent={(e) => { this.onCustomEvent(e) }}  />
            , document.getElementById('example')
        );*/
        $('body').scrollspy({ target: '.form-navigation', offset: 100 });
    };

    componentWillUnmount() {
        if (this.formioFormRef) {
            try {
                this.formioFormRef.formio.destroy();
            }
            catch (e) { }
        }
    };

    componentDidUpdate() {
        console.log('componentDidUpdate', this.formioFormRef);
    };

    /*componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(nextProps);
        }
        console.log('--------> nextProps', nextProps, this.props)
    };*/

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.component !== nextProps.component;
    };

    cleanUpData = (schema, data) => {
        const componentsMap = FormioUtils.flattenComponents(schema.components);

        Object.keys(componentsMap).forEach(function (key) {
            const c = componentsMap[key];
            // rj - 11/29/2021 - old commented logic - this whole function was commented but to fix the date -1 issue, i am commenting the old body of this function and added new logic right after this commented block
            /*if (c.type == 'datetime' && c._conditionalDate) {
                //const logic = c?.logic?.find(l => l.actions?.[0]?.property.value == 'hidden' || l.actions?.[0]?.property.value == 'disabled');
                if(/ *logic && * /c._initialValue && c._initialValue != '') {
                    // formio has a bug with conditional dates where it sets the date to the default date minus 1 day
                    // the code below is the workaround for that - by setting the default date to 1 day in the future
                    const value = moment(c._initialValue, c._serverFormat.toUpperCase()).add(1, 'days').format(c._serverFormat.toUpperCase());
                    data[c.key] = value;
                }
            }*/
            // rj - 11/29/2021 - new logic
            if (c.type == 'datetime') {
                const parent = c._parent;
                if(parent.type == 'EDITGRID' && data[parent.code]) {
                    data[parent.code]?.map(r => {
                        const formattedValue = moment(r[c.key], c._serverFormat.toUpperCase()).format(process.env.REACT_APP_FORMDATA_DATE_FORMAT.toUpperCase());
                        //console.log('r', r, r[c.key], formattedValue);
                        if(formattedValue == 'Invalid date')
                            r[c.key] = '';
                        else
                            r[c.key] = formattedValue;
                    })
                }
                else if(data[key]) {
                    const value = data[key];
                    const formattedValue = moment(value, c._serverFormat.toUpperCase()).format(process.env.REACT_APP_FORMDATA_DATE_FORMAT.toUpperCase());
                    //console.log('value', key, value, formattedValue);
                    if(formattedValue == 'Invalid date')
                        data[key] = '';
                    else
                        data[key] = formattedValue;
                }
            }
        });

        //console.log('cleanUpData > data', data);

        return data;
    };

    draw() {
        const component = this.props.component;
        const schema = this.generateSchema(this.props.component);
        const submission = { data: this.cleanUpData(schema, this.props.component?.data?.data) };
        //console.log('DRAW ********!!!!!!!', schema, submission, this.props.params);
        //console.log('DRAW!!!!!!!', submission);

        const userConfig = getUserConfig();
        const lang = userConfig?.filter((config) => config.key == "locale");
        const options = {};

        if (lang?.length)
            options["language"] = lang[0].value;

        const formio = <Formio form={schema} options={options} ref={ref => { this.formioFormRef = ref; window.fio = ref; window.fioRenderer = this; }}
            /*onRender={(e) => {console.log('1 onRender', e, this.formioFormRef) }} 
            onFormLoad={(e) => {console.log('2 onFormLoad', e, this.formioFormRef) }} 
            onComponentChange={(e) => {console.log('3 onComponentChange', e, this.formioFormRef) }}
            onChange={(e) => {console.log('4 onChange', e, this.formioFormRef) }} 
            onBuild={(e) => {console.log('5 onBuild', e, this.formioFormRef) }} 
            onInitialized={(e) => {console.log('6 onInitialized', e, this.formioFormRef) }} 
            onAttach={(e) => {console.log('7 onAttach', e, this.formioFormRef) }}
            onSubmit={this.onSubmit}/**/
            submission={submission}
            /*onFormLoad={(e) => {console.log('1 onFormLoad', e, this.formioFormRef) }} 
            onError={(e) => {console.log('2 onError', e, this.formioFormRef) }} 
            onRender={(e) => {console.log('3 onRender', e, this.formioFormRef) }} 
            onAttach={(e) => {console.log('5 onAttach', e, this.formioFormRef) }} 
            onBuild={(e) => {console.log('6 onBuild', e, this.formioFormRef) }} 
            onInitialized={(e) => {console.log('7 onInitialized', e, this.formioFormRef) }} 
            onAttach={(e) => {console.log('onAttach', e, this.formioFormRef) }} */
            onInitialized={(e) => { this.onInitialized(e) }}
            onChange={(e) => { this.onChange(e) }}
            onCustomEvent={(e) => { this.onCustomEvent(e) }} />;
        //console.log(component);
        //console.log(schema);
        //console.log(this.state);
        //console.log('-------', formio);
        if (this.isAffix()) {
            const groups = component?.elements?.[0]?.elements;
            const elements = [];
            groups.map(group => {
                group?.elements.map(step => {
                    if (step?.properties?.hidden == false && step?.properties?.label != '')
                        elements.push(step);
                })
            });
            return (
                <>
                    <div className="row form-renderer-row">
                        <div className="col-12 col-md-9 form-renderer-col-form">
                            {
                                this.props.reviewContainer
                                    ? this.props.reviewContainer
                                    : null
                            }
                            {formio}
                        </div>
                        <div className="col-3 d-none d-md-block form-renderer-col-affix">
                            <StickyBox offsetTop={120} offsetBottom={20} className="sps sps--abv">
                                <nav className="form-navigation">
                                    <ul className="nav nav-pills flex-column">
                                        {
                                            elements?.map((step, i) => {
                                                return (
                                                    <li id={`affix-nav-item-${step.code}`} className="nav-item" key={`affix-nav-item-${i}`}>
                                                        <a className={i == 0 ? 'nav-link active' : 'nav-link'} key={`${step.code}-${i}`} href={`#${step.code}`} onClick={this.scrollTo}>
                                                            {i18next.t("form:"+step?.code) != step?.code ? i18next.t("form:"+step?.code) : step?.properties?.label}
                                                        </a>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </nav>
                            </StickyBox>
                        </div>
                    </div>
                    <ViewModal />
                </>
            );
        }
        else
            return (
                <div className="row">
                    <div className="col-12">
                        {
                            this.props.reviewContainer
                                ? this.props.reviewContainer
                                : null
                        }
                        {formio}
                    </div>
                </div>
            );
    };
};