import React from 'react';
import ViewDefinitionService from "../../../Services/ViewDefinitionService";
import LogUtil from "../../../../Utils/LogUtil";
import { Component } from '../../internal';
import NoDataFound from "../../../../Common/NoDataFound";
import Loader from "../../../../Common/Loader";

import "./List.css";

export class List extends Component {

  state = {
    loading: false,
    component: null,
    data: null
  };

  componentDidMount() {
    const component = this.props.component;
    const records = component?.elements?.filter(c => c?.properties?.hidden != true);
    records.elements = records?.sort(function (a, b) {
      return a?.properties?.order - b?.properties?.order;
    });
    this.setState({ component: component, records: records }, this.getData);
  };

  getData = () => {
    if (this.state.component?.data && !this.state.component?.data?.error)
      this.setState({ data: this.state.component?.data });
    else if (this.state.component?.properties?.initServiceUrl) {
      this.setState({ loading: true });
      new ViewDefinitionService().getViewByUrl(this.state.component?.properties?.initServiceUrl).then((response) => {
        this.setState({ loading: false, data: response.data });
      })
        .catch((error) => {
          LogUtil.error(error);
          this.setState({ loading: false, data: [] });
        });
    }
  };

  renderData = () => {
    const dataPath = this.props.component?.properties?.dataPath;
    const data = (dataPath) ? this.state.data?.[dataPath] : this.state.data;
    if (data?.length)
      return this.renderListRecords(data);
    else {
      if (this.state.loading)
        return <Loader />;
      else
        return <NoDataFound />;
    }
  };

  renderListRecords = (data) => {
    return data.map((listRecords, i) => {
      return this.renderRecords(listRecords);
    });
  };

  renderRecords = (data) => {
    return this.state.records?.map((record, i) => {
      return this.renderRecord(record, data, i);
    });
  };

  renderRecord = (record, data, i) => {
    const properties = record?.properties;
    return (
      <div className="mdf-list-records" key={`list-records-${i}`}>
        <div className="mdf-list-record-label text-100 font-weight-bold">{properties.header}</div>
        <div className={"mdf-list-record-value text-break " + (properties?.cssClassName || '')}>{this.renderComponents(record?.elements, data, i)}</div>
      </div>
    );
  };

  buildList = (component) => {
    return <div className="textdata">{this.renderData()}</div>
  };

  draw() {
    return this.buildList(this.props.component);
  };
}
