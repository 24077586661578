import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";

// App Routes
import Routes from "./Routes";

// Vendor dependencies
import "./Vendor";
// Application Styles
import "./App.css";

import SystemService from "./components/PortalPages/Services/SystemService";

class App extends Component {

  constructor(props) {
    super(props);
  };
  
  componentDidMount() {
    this.getSystemConfig();
  };

  getSystemConfig = async () => {
    await (new SystemService()).get();
  };

  render() {
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    
    const basename = process.env.NODE_ENV === "development" ? "/" : process.env.PUBLIC_URL || "/";

    return (
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>
    );
  }
}

export default App;
