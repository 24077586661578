import axios from 'axios';
// import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getAccessToken, refreshToken, destroyToken, logout, goToLogin, getUserConfig } from './Token';

var interceptor = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 181000,
  headers: {
    Accept: "application/json",
    //"Access-Control-Allow-Origin": "*",
    //Authorization: "bearer " + localStorage.getItem("access_token")
  },
});

// Add a request interceptor
interceptor.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = getAccessToken();
    let configWithHeaders = null;

    // Token is null on initial login
    if (config?.interceptToken !== false && token) {

      let acceptLang = navigator.language || process.env.REACT_APP_DEFAULT_LANG;
      let acceptLocale = navigator.language || process.env.REACT_APP_DEFAULT_LANG;
      const userConfig = getUserConfig();
      userConfig?.map((config) => {
        if (config.key == "language")
          acceptLang = config.value;
        if (config.key == "locale")
          acceptLocale = config.value;
      });
      configWithHeaders = {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token}`, 'Accept-Locale': acceptLocale, 'Accept-Language': acceptLang },
      };
    }
    return configWithHeaders || config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

// Add a response interceptor
interceptor.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // Return any error which is not due to authentication back to the calling service
    if (error.response?.status === 401) {
      if (error.config?.lastRequestUnauthorized == true) {
        logout();
        return Promise.reject();
      }
      return refreshToken().then((token) => {
        var req = error.config;
        req.headers = {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + token
        };
        req.lastRequestUnauthorized = true;
        return interceptor.request(req);
      })
        .catch((error) => {
          destroyToken();
          goToLogin();
          return Promise.reject(error);
        });
    }
    else {
      return new Promise((_, reject) => {
        reject(error);
      });
    }
  },
);

export default interceptor;
