import { toast } from 'react-toastify';
import UiUtil from './UiUtil';

export default class ServiceUtil {

    static hasError = (data) => {
        return (data != null && (data?.error || data?.messages?.filter(m => m?.type == 'E' || m?.Type == 'E')?.length));
    };

    static hasWarning = (data) => {
        return (data != null && (data?.error || data?.messages?.filter(m => m?.type == 'W' || m?.Type == 'W')?.length));
    };

    static hasSuccess = (data) => {
        return (data != null && data?.messages?.filter(m => m?.type == 'S' || m?.Type == 'S')?.length);
    };

    static hasInfo = (data) => {
        return (data != null && data?.messages?.filter(m => m?.type == 'I' || m?.Type == 'I')?.length);
    };

    static showMessages = (title, data) => {
        if(data?.response?.error?.message?.value) {
            toast("Oops, something went wrong!");
            console.log(data?.response?.error?.message?.value);
        }
        else if(data?.messages?.length) {
            const messages = data.messages.map(m => { return m.message || m.Message });
            UiUtil.showAlert({ title, text: messages.join('\n\n') });
            console.log(data.messages, messages);
        }
        else if(data?.error?.message?.value)
            UiUtil.showAlert({ title, text: data.error.message.value });
    };

    static showToMessages = (title, data) => {
        const messages = Array.isArray(data) ? data : data?.messages || data?.toMessages || null;
        if(messages?.length) {
            const messageTexts = messages.map(m => m.message || m.Message);
            UiUtil.showAlert({ title, text: messageTexts.join('\n') });
        }
        else 
            console.log('No messages found in ', data)
    };

    static debounce = (func, wait, immediate) => {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };    
}