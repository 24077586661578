const Menu = {
  id: "100",
  isDefault: true,
  menuItems:
  [
    {
      text: "Home",
      link: "/hrd/home",
      icon: "fa fa-desktop"
    },
    {
      text: "Org View",
      link: "/liveorg/home",
      icon: "fa fa-sitemap"
    },
    {
      text: "Inbox",
      link: "/hrd/inbox",
      icon: "fa fa-inbox"
    },
    {
      text: "Form Status",
      link: "/hrd/form/status",
      icon: "fa fa-file"
    },
    {
      text: "Launcher",
      link: "/mdf/launcher",
      icon: "fa fa-rocket",
      permissions: ["PORTAL_ALL"]
    },
    {
      text: "Permissions",
      link: "/portal/permissions",
      icon: "fa fa-lock",
      permissions: ["PORTAL_ALL"]
    },
    {
      text: "Roles",
      link: "/portal/roles",
      icon: "fas fa-user-tag",
      permissions: ["PORTAL_ALL"]
    },
    {
      text: "Users",
      link: "/portal/users",
      icon: "fa fa-user",
      permissions: ["PORTAL_ALL"]
    }
  ]
};

export default Menu;
