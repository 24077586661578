import ApiService from "../../Services/ApiService";

export default class ViewService {

  getViews = () => {
    const url = "/views";
    return new ApiService().get(url);
  };

  getView = (id, params) => {
    const paramsQs = typeof(params)=='string' ? params : encodeURIComponent(JSON.stringify(params));
    const url = '/views/' + id + '?params=' + paramsQs;
    return new ApiService().get(url);
  };

  saveViewData = (url, data) => {
    const baseUrl = (url?.indexOf('://') > -1) ? url : null;
    const serviceEndPoint = (url?.indexOf('://') > -1) ? '' : url;
    if(data._id)
      return new ApiService(baseUrl).put(serviceEndPoint, data);
    else
      return new ApiService(baseUrl).post(serviceEndPoint, data);
  };

  getViewByUrl = (url, params) => {
    const paramsQs = typeof(params)=='string' ? params : encodeURIComponent(JSON.stringify(params));
    url += (url?.indexOf('?') > -1) ? '&params=' : '?params=' + paramsQs;
    const baseUrl = (url?.indexOf('://') > -1) ? url : null;
    const serviceEndPoint = (url?.indexOf('://') > -1) ? '' : url;
    return new ApiService(baseUrl).get(serviceEndPoint);
  };

  refreshView = (type, id, transformation, version) => {
    const url = (version) ? `/views/${type}/${id}/${transformation}?version=${version}` : `/views/${type}/${id}/${transformation}`;
    return new ApiService().post(url);
  };
}
