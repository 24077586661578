import React from 'react';
import DataUtil from '../../../../Utils/DataUtil';
import { Component, View } from '../../internal';

export class Column extends Component {

  buildColumn = (component) => {
    return (
      component?.elements?.map((column, i) => {
        return <View id={column?.properties.view} key={DataUtil.generateKey('COLUMN')} options={this.props.options} params={this.props.params} formType={this.props.formType} />
      })
    );
  };

  draw() {
    return this.buildColumn(this.props.component);
  };
}
