import React from 'react';
import Loader from './Loader';

// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const PageLoader = ({ className }) => (
    <div className={`page-loader text-center ${className}`}>
        <Loader />
    </div>
)

export default PageLoader;