import React, { Component } from "react";
import ReactDOM from "react-dom";
import i18next from 'i18next';
import { ReactComponent } from "react-formio";
import settingsForm from "./FileUpload.settingsForm";

import DataUtil from "../../../../../../Utils/DataUtil";
import UiUtil from "../../../../../../Utils/UiUtil";
import EntityService from "../../../../../Services/EntityService";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const CustomComp = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            viewDefinition: null,
            files: null,
            selectedFile: null // Initially, no file is selected
        };
    };

    setValue = (value) => {
        this.setState({ value }, () => this.props.onChange(this.state.value, null));
    };

    handleChange = (e) => {
        this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } });
    };

    componentDidMount() {
        //console.log('componentDidMount', '************** FILE UPLOAD =============>', this.state.files);
        this.fetchFiles();
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => { return; };
    };

    /*shouldComponentUpdate(nextProps, nextState) {
        return this.state != nextState;
    };*/

    setLoading = () => {
        const component = this.props.formioComponent;
        component?.setLoading(component?.element?.getElementsByClassName('col-form-label')?.[0], true);
    };

    removeLoading = () => {
        const component = this.props.formioComponent;
        component?.setLoading(component?.element?.getElementsByClassName('col-form-label')?.[0], false);
    };

    fetchFiles = async (index='') => {
        const component = this.props.component;
        const formElement = component._formElement;
        const formProperties = formElement.properties;
        const element = component._element;
        const properties = element.properties;
        const response = await new EntityService().getDefinitionDataByUrl(properties.url, { ProcessGuid: formProperties.ProcessGuid, ProcessId: formProperties.ProcessId, ProcessReferenceNumber: formProperties.ProcessReferenceNumber, AttachmentIndex: index });
        const files = response.data?.d?.results?.filter(f => f.AttachmentIndex != '0000') || [];
        this.setState({ files });
        this.setValue(files?.length ? JSON.stringify(files) : null);
    };

    getFiles = () => {
        return this.state.files;
    };

    downloadFile = async (file) => {
        const component = this.props.component;
        const formElement = component._formElement;
        const formProperties = formElement.properties;
        const element = component._element;
        const properties = element.properties;
        const response = await new EntityService().getDefinitionDataByUrl(properties.url, { ProcessGuid: formProperties.ProcessGuid, ProcessId: formProperties.ProcessId, ProcessReferenceNumber: formProperties.ProcessReferenceNumber, AttachmentIndex: file.AttachmentIndex });
        const fileWithContent = response.data?.d?.results?.[0];
        let fetchDataModified = `data:${fileWithContent.AttachmentMimeType};base64,${fileWithContent.AttachmentContent}`;
        let a = document.createElement("a");
        a.href = fetchDataModified;
        a.download = fileWithContent.AttachmentFileName;
        a.click();
    };

    deleteFile = (file) => {
        UiUtil.showAlert({ 
            icon: 'error', 
            title: i18next.t("form:fileUploadDeleteTitle"), 
            text: i18next.t("form:fileUploadDeleteDesc"), 
            buttons: { 
                delete: { text: i18next.t("form:fileUploadDelete"), value: true, visible: true, closeModal: true }, 
                cancel: { text: i18next.t("form:fileUploadCancel"), value: false, visible: true, closeModal: true, className: 'swal-button--cancel' } 
            } 
        }, (leave) => { if(leave) this.doDeleteFile(file) });
    };

    doDeleteFile = async (file) => {
        const component = this.props.component;
        const formElement = component._formElement;
        const formProperties = formElement.properties;
        const element = component._element;
        const properties = element.properties;
        // Create an object of formData
        const formData = {};
      
        // Update the formData object
        formData['AttachmentFileName'] = file.name;
        formData['Operation'] = 'D';
        formData['AttachmentIndex'] = file.AttachmentIndex;
        formData['ProcessGuid'] = formProperties.ProcessGuid;
        formData['AttachmentGuid'] = '';
        formData['AttachmentMimeType'] = file.type;
        formData['AttachmentType'] = element.code;
        formData['ProcessId'] = formProperties.ProcessId;
        formData['ProcessReferenceNumber'] = formProperties.ProcessReferenceNumber;
        formData['AttachmentTypeText'] = element.name;
        //formData['ModifyAllowed'] = true;
        //formData['DisplayAllowed'] = true;
        formData['params'] = { ProcessGuid: formProperties.ProcessGuid, ProcessId: formProperties.ProcessId, ProcessReferenceNumber: formProperties.ProcessReferenceNumber, AttachmentIndex: file.AttachmentIndex };
            
        this.setLoading();

        // Request made to the backend api
        // Send formData object
        await new EntityService().postData(properties.url, formData);
        
        this.fetchFiles();
        this.removeLoading();
    };
    
    openFileSelector = () => {
        this.fileRef?.click();
    };

    // On file select (from the pop up)
    onFileChange = async (event) => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] }, this.onFileUpload);
    };

    // On file upload (click the upload button)
    onFileUpload = async () => {
        const component = this.props.component;
        const formElement = component._formElement;
        const formProperties = formElement.properties;
        const element = component._element;
        const properties = element.properties;
        // extract selected file
        const selectedFile = this.state.selectedFile;
        // Create an object of formData
        const formData = {};
        // convert file to base64
        const selectedFile64 = await DataUtil.getBase64(selectedFile);
      
        // Update the formData object
        formData['AttachmentFileName'] = selectedFile.name;
        formData['Operation'] = 'I';
        formData['AttachmentIndex'] = '';
        formData['ProcessGuid'] = formProperties.ProcessGuid;
        formData['AttachmentGuid'] = '';
        formData['AttachmentMimeType'] = selectedFile.type;
        formData['AttachmentType'] = element.code;
        formData['ProcessId'] = formProperties.ProcessId;
        formData['ProcessReferenceNumber'] = formProperties.ProcessReferenceNumber;
        formData['AttachmentTypeText'] = element.name;
        formData['AttachmentContent'] = selectedFile64?.split(';base64,')?.[1];
        //formData['ModifyAllowed'] = true;
        //formData['DisplayAllowed'] = true;
        formData['params'] = { ProcessGuid: formProperties.ProcessGuid, ProcessId: formProperties.ProcessId, ProcessReferenceNumber: formProperties.ProcessReferenceNumber, AttachmentIndex: '' };
            
        this.setLoading();

        // Request made to the backend api
        // Send formData object
        await new EntityService().postData(properties.url, formData);
        
        this.fetchFiles();
        this.removeLoading();

        this.setState({ selectedFile: null });
    };

    // File content to be displayed after
    // file upload is complete
    /*fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div className="mt-3 text-90">
                    <div className="text-600">File to Upload:</div>
                    <div><span className="text-secondary">File Name:</span> {this.state.selectedFile.name}</div>
                    <div><span className="text-secondary">File Type:</span> {this.state.selectedFile.type}</div>
                    <div><span className="text-secondary">Last Modified:</span> {DataUtil.getDateTime(this.state.selectedFile.lastModifiedDate)}</div>
                </div>
            );
        }
        else
            return null;
    };*/

    // file upload is complete
    uploadedFiles = () => {
        if (this.state.files?.length) {
            return (
                <ul className="editgrid-listgroup list-group mt-3">
                    <li className="list-group-item list-group-header">
                        <div className="row">
                            <div className="text-600 col-sm-10">{i18next.t("form:fileUploadFile")}</div>
                            <div className="col-sm-2"></div>
                        </div>
                    </li>
                {
                    this.state.files.map(file => {
                        return (
                            <li className="list-group-item list-group-header" key={file.AttachmentIndex}>
                                <div className="row">
                                    <div className="col-sm-10"><a href={void(0)} className="decorate-link" onClick={() => this.downloadFile(file)}>{file.AttachmentFileName}</a></div>
                                    <div className="col-sm-2">
                                        <button type="button" className="close text-115 font-medium" onClick={() => { this.deleteFile(file) }}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                }
                </ul>
            );
        }
        else
            return null;
    };

    render() {
        //const component = this.props.component;
        return (
            <>
                <div className="input-group formio-component" ref="element">
                    <input className="form-control bg-white d-none" type="file" onChange={this.onFileChange} ref={ref => { this.fileRef = ref; }} />
                    <div className="form-control d-table">
                    {
                        this.state.selectedFile
                        ? <span>{i18next.t("form:uploading")} <b>{this.state.selectedFile.name}</b></span>
                        : <div onClick={this.openFileSelector}><button className="btn btn-purple btn-xs">{i18next.t("form:chooseFile")}</button> <span className="ml-15 mt-15 text-90">{i18next.t("form:noFileChosen")}</span></div>
                    }
                    </div>
                    {/*
                        component.disabled || !this.state.selectedFile
                        ? null
                        : (
                            <span className="input-group-addon input-group-append pointer" onClick={this.onFileUpload}>
                                <span className="input-group-text py-0">Upload <i className="fa fa-upload ml-2"></i></span>
                            </span>
                        )
                    */}
                </div>
                {/* { this.fileData() } */}
                { this.uploadedFiles() }
            </>
        );
    }
};

export default class FileUpload extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
    static get builderInfo() {
        return {
            title: "FileUpload",
            icon: "square",
            group: "Data",
            documentation: "",
            weight: -10,
            schema: FileUpload.schema()
        };
    }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
    static schema() {
        return ReactComponent.schema({
            type: "fileUploadCustomComp",
            label: "Default Label"
        });
    }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
    static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
    attachReact(element) {
        return ReactDOM.render(
            <CustomComp
                formioComponent={this}
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                ref={ref => { this.componentRef = ref; }}
            />,
            element
        );
    }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    };

    fetchFiles = async () => {
        if(this.componentRef?.getFiles() == null)
            await this.componentRef?.fetchFiles();
        return this.componentRef?.getFiles() || [];
    };

    getFiles = () => {
        return this.componentRef?.getFiles() || [];
    };

    downloadFile = (file) => {
        return this.componentRef?.downloadFile(file);
    };
}
