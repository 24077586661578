import React from 'react';
import DataUtil from '../../../../Utils/DataUtil';
import EntityService from '../../../Services/EntityService';
import { Component } from '../../internal';

const Icon = ({ option }) => {
  return (option?.properties?.icon) ? <i className={"far fa fas w-3 text-110 " + option?.properties?.icon}></i> : '';
};

const Option = ({ option, data, component, listeners }) => {
  //console.log(option);
  //all dropdowns had this // btn btn-outline-primary border-l-3 btn-brc-tp btn-h-lighter-primary btn-h-brc-tp btn-a-outline-primary btn-a-text-dark btn-a-bold m-0 radius-l-0 py-1 px-2
  if (typeof listeners?.onOptionClick == 'function') {
    return (
      <a href={void (0)} onClick={(e) => { listeners.onOptionClick(option, data, component, e) }} className="dropdown-item">
        <Icon option={option} /> {option?.properties?.label}
      </a>
    )
  }
  else if (option?.properties?.serviceView) {
    const params = JSON.stringify(data);
    return (
      <a href={'viewrenderer?id=' + option?.properties?.serviceView + ((params) ? '&params=' + params : '')} className="dropdown-item">
        <Icon option={option} /> {option?.properties?.label}
      </a>
    )
  }
  else {
    return (
      <a href={void (0)} onClick={() => { optionClick(option, data) }} className="dropdown-item">
        <Icon option={option} /> {option?.properties?.label}
      </a>
    )
  }
};

const optionClick = async (option, data) => {
  const serv = new EntityService();
  const url = DataUtil.fillTemplate(option?.properties?.serviceUrl, data);
  if (option?.properties?.method == 'DELETE') {
    await serv.delete(url);
    window.location.reload();
  }
};

export class Options extends Component {

  isHidden = (condition, row, component) => {
    let hidden = true;
    try {
      const func = `((row, DataUtil) => { let result = true; ${condition}; return result; })`;
      hidden = eval('(' + func + ')')(row, DataUtil);
      //console.log(hidden, condition, row, component, this.props.params);
    }
    catch (e) {
      console.warn('', e);
    }
    return hidden;
  };

  buildOptions = (component, data) => {
    const listeners = this.props.listeners;
    const conditionalHidden = component?.properties?.conditionalHidden;
    //console.log(component, data, listeners)
    if (component?.properties?.hidden)
      return '';
    else if (conditionalHidden && conditionalHidden != '' && this.isHidden(conditionalHidden, data, component))
      return '';
    return (
      <div className={(component?.properties?.cssClassName) ? 'dropdown ' + component?.properties?.cssClassName : 'dropdown d-inline-block'}>
        <a className="btn btn-outline-default shadow-sm text-600 letter-spacing dropdown-toggle mb-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {component?.properties?.label} <i className={`fa ${component?.properties?.icon} icon-only ${component?.properties?.label && component?.properties?.label != "" ? "ml-15" : ""} f-n-hover`}></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right dropdown-caret px-1px py-2px border-1 brc-default-l1 shadow radius-1 dropdown-animated animated-2">
          {
            component?.elements?.map((option, i) => {
              const optionConditionalHidden = option?.properties?.conditionalHidden;
              if (option?.properties?.hidden)
                return '';
              else if (optionConditionalHidden && optionConditionalHidden != '' && this.isHidden(optionConditionalHidden, data, option))
                return '';
              return <Option option={option} data={data} component={component} listeners={listeners} key={'option-' + i} />
            })
          }
        </div>
      </div>
    )
  };

  draw() {
    //console.log(this.props.component)
    return this.buildOptions(this.props.component, this.props.data);
  };
}
