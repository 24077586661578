import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import $ from 'jquery'; 
import { View } from "../../MDF/UIComponents";

export class ViewModal extends React.Component {

    state = {
        openModal: false,
        title: '',
        view: null,
        params: {}
    };

    componentDidMount() {
        $(document).off('click', '[data-info-view]').on('click', '[data-info-view]', (e) => {
            var $elem = $(e.currentTarget);
            var title = $elem.attr('data-info-title');
            var view = $elem.attr('data-info-view');
            var params = $elem.attr('data-info-params');
            var preventDefault = $elem.attr('data-info-prevent-default');
            this.toggleModal({ title, view, params });
            if(preventDefault == 'true')
                e.preventDefault();
        });

        $(document).off('click', '[data-info-text]').on('click', '[data-info-text]', (e) => {
            var $elem = $(e.currentTarget);
            var title = $elem.attr('data-info-title');
            var text = $elem.attr('data-info-text');
            var preventDefault = $elem.attr('data-info-prevent-default');
            this.toggleModal({ title, text });
            if(preventDefault == 'true')
                e.preventDefault();
        });

        $(document).off('click', '[data-info-html]').on('click', '[data-info-html]', (e) => {
            var $elem = $(e.currentTarget);
            var title = $elem.attr('data-info-title');
            var html = $elem.attr('data-info-html');
            var preventDefault = $elem.attr('data-info-prevent-default');
            this.toggleModal({ title, html });
            if(preventDefault == 'true')
                e.preventDefault();
        });
    };

    toggleModal = ({ title='', text=null, html=null, view=null, params={} }) => {
        this.setState({ openModal: !this.state.openModal, title, text, html, view, params });
    };

    render() {
        return (
            <Modal style={{maxWidth: '800px', width: '100%'}} isOpen={this.state.openModal} toggle={() => this.toggleModal({ text: this.state.text, view: this.state.view, params: this.state.params })}>
                <ModalHeader toggle={() => this.toggleModal({ text: this.state.text, view: this.state.view, params: this.state.params })}>{this.state.title}</ModalHeader>
                <ModalBody>
                {
                    this.state.view != null
                    ? <View id={this.state.view} params={{}} />
                    : (
                        this.state.text != null
                        ? <div>{this.state.text}</div>
                        : (
                            this.state.html != null
                            ? <div dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
                            : null
                        )
                    )
                }
                </ModalBody>
            </Modal>
        );
    }
};