import React from 'react';
import { Component, Column } from '../../internal';

export class Columns extends Component {

  buildColumns = (component) => {
    return (
      <div className={component?.properties?.cssClassName || ''}>
        {
          component?.elements?.map((column, i) => {
            return (
              <div className={column?.properties?.cssClassName || ''} key={`col-${i}`}>
                { /*<View id={column?.properties.} params={this.props.params} />*/}
                { /*this.renderComponents(column?.elements, {}, i)*/}
                <Column component={column} data={this.props.data} formType={this.props.formType} options={this.props.options} params={this.props.params} />
              </div>
            )
          })
        }
      </div>
    );
  };

  draw() {
    return this.buildColumns(this.props.component);
  };
}
