import moment from 'moment';
import avatarEmp from "../../assets/image/avatar/generic-avatar.png";
import avatarPos from "../../assets/image/avatar/generic-position.png";
import avatarOrg from "../../assets/image/avatar/generic-org.png";
import { getUserConfig } from '../Services/Interceptors/Token';

export default class DataUtil {

    static getInitials = (fullName) => {
        let nameArray = fullName.split(' ');
        let initials = '';

        if (nameArray[0])
            initials += DataUtil.getInitial(nameArray[0]);
        if (nameArray[1])
            initials += DataUtil.getInitial(nameArray[1]);

        return initials;
    };

    static getInitial = (name) => {
        if (name)
            return name.charAt(0);
        return '';
    };

    static getUserFormat = () => {

        const userConfig = getUserConfig();
        const locale = userConfig?.filter((config) => config.key == "locale");

        return {
            date: DataUtil.getLocaleDateString(locale && locale.length ? locale[0].value : navigator.language),
            number: null
        };
    };

    static getLocaleDateString = (locale) => {

        //console.log(locale)
        try {
            if (locale)
                locale = locale.split('-')[0] + "-" + locale.split('-')[1].toUpperCase();
        } catch (e) {
            console.error(e);
            locale = "en-US";
        }

        const formats = {
            "af-ZA": "yyyy/MM/dd",
            "am-ET": "dd/MM/yyyy",
            "ar-AE": "dd/MM/yyyy",
            "ar-BH": "dd/MM/yyyy",
            "ar-DZ": "dd-MM-yyyy",
            "ar-EG": "dd/MM/yyyy",
            "ar-IQ": "dd/MM/yyyy",
            "ar-JO": "dd/MM/yyyy",
            "ar-KW": "dd/MM/yyyy",
            "ar-LB": "dd/MM/yyyy",
            "ar-LY": "dd/MM/yyyy",
            "ar-MA": "dd-MM-yyyy",
            "ar-OM": "dd/MM/yyyy",
            "ar-QA": "dd/MM/yyyy",
            "ar-SA": "dd/MM/yy",
            "ar-SY": "dd/MM/yyyy",
            "ar-TN": "dd-MM-yyyy",
            "ar-YE": "dd/MM/yyyy",
            "arn-CL": "dd-MM-yyyy",
            "as-IN": "dd-MM-yyyy",
            "az-Cyrl-AZ": "dd.MM.yyyy",
            "az-Latn-AZ": "dd.MM.yyyy",
            "ba-RU": "dd.MM.yy",
            "be-BY": "dd.MM.yyyy",
            "bg-BG": "dd.M.yyyy",
            "bn-BD": "dd-MM-yy",
            "bn-IN": "dd-MM-yy",
            "bo-CN": "yyyy/M/d",
            "br-FR": "dd/MM/yyyy",
            "bs-Cyrl-BA": "dd.MM.yyyy",
            "bs-Latn-BA": "dd.MM.yyyy",
            "ca-ES": "dd/MM/yyyy",
            "co-FR": "dd/MM/yyyy",
            "cs-CZ": "dd.MM.yyyy",
            "cy-GB": "dd/MM/yyyy",
            "da-DK": "dd-MM-yyyy",
            "de-AT": "dd.MM.yyyy",
            "de-CH": "dd.MM.yyyy",
            "de-DE": "dd.MM.yyyy",
            "de-LI": "dd.MM.yyyy",
            "de-LU": "dd.MM.yyyy",
            "dsb-DE": "dd. MM. yyyy",
            "dv-MV": "dd/MM/yy",
            "el-GR": "dd/MM/yyyy",
            "en-029": "MM/dd/yyyy",
            "en-AU": "dd/MM/yyyy",
            "en-BZ": "dd/MM/yyyy",
            "en-CA": "dd/MM/yyyy",
            "en-GB": "dd/MM/yyyy",
            "en-IE": "dd/MM/yyyy",
            "en-IN": "dd-MM-yyyy",
            "en-JM": "dd/MM/yyyy",
            "en-MY": "dd/MM/yyyy",
            "en-NZ": "dd/MM/yyyy",
            "en-PH": "MM/dd/yyyy",
            "en-SG": "dd/MM/yyyy",
            "en-TT": "dd/MM/yyyy",
            "en-US": "MM/dd/yyyy",
            "en-ZA": "yyyy/MM/dd",
            "en-ZW": "MM/dd/yyyy",
            "es-AR": "dd/MM/yyyy",
            "es-BO": "dd/MM/yyyy",
            "es-CL": "dd-MM-yyyy",
            "es-CO": "dd/MM/yyyy",
            "es-CR": "dd/MM/yyyy",
            "es-DO": "dd/MM/yyyy",
            "es-EC": "dd/MM/yyyy",
            "es-ES": "dd/MM/yyyy",
            "es-GT": "dd/MM/yyyy",
            "es-HN": "dd/MM/yyyy",
            "es-MX": "dd/MM/yyyy",
            "es-NI": "dd/MM/yyyy",
            "es-PA": "MM/dd/yyyy",
            "es-PE": "dd/MM/yyyy",
            "es-PR": "dd/MM/yyyy",
            "es-PY": "dd/MM/yyyy",
            "es-SV": "dd/MM/yyyy",
            "es-US": "MM/dd/yyyy",
            "es-UY": "dd/MM/yyyy",
            "es-VE": "dd/MM/yyyy",
            "et-EE": "dd.MM.yyyy",
            "eu-ES": "yyyy/MM/dd",
            "fa-IR": "MM/dd/yyyy",
            "fi-FI": "dd.MM.yyyy",
            "fil-PH": "MM/dd/yyyy",
            "fo-FO": "dd-MM-yyyy",
            "fr-BE": "dd/MM/yyyy",
            "fr-CA": "yyyy-MM-dd",
            "fr-CH": "dd.MM.yyyy",
            "fr-FR": "dd/MM/yyyy",
            "fr-LU": "dd/MM/yyyy",
            "fr-MC": "dd/MM/yyyy",
            "fy-NL": "dd-MM-yyyy",
            "ga-IE": "dd/MM/yyyy",
            "gd-GB": "dd/MM/yyyy",
            "gl-ES": "dd/MM/yy",
            "gsw-FR": "dd/MM/yyyy",
            "gu-IN": "dd-MM-yy",
            "ha-Latn-NG": "dd/MM/yyyy",
            "he-IL": "dd/MM/yyyy",
            "hi-IN": "dd-MM-yyyy",
            "hr-BA": "dd.MM.yyyy.",
            "hr-HR": "dd.MM.yyyy",
            "hsb-DE": "dd. MM. yyyy",
            "hu-HU": "yyyy. MM. dd.",
            "hy-AM": "dd.MM.yyyy",
            "id-ID": "dd/MM/yyyy",
            "ig-NG": "dd/MM/yyyy",
            "ii-CN": "yyyy/M/d",
            "is-IS": "dd.MM.yyyy",
            "it-CH": "dd.MM.yyyy",
            "it-IT": "dd/MM/yyyy",
            "iu-Cans-CA": "dd/MM/yyyy",
            "iu-Latn-CA": "dd/MM/yyyy",
            "ja-JP": "yyyy/MM/dd",
            "ka-GE": "dd.MM.yyyy",
            "kk-KZ": "dd.MM.yyyy",
            "kl-GL": "dd-MM-yyyy",
            "km-KH": "yyyy-MM-dd",
            "kn-IN": "dd-MM-yy",
            "ko-KR": "yyyy-MM-dd",
            "kok-IN": "dd-MM-yyyy",
            "ky-KG": "dd.MM.yy",
            "lb-LU": "dd/MM/yyyy",
            "lo-LA": "dd/MM/yyyy",
            "lt-LT": "yyyy.MM.dd",
            "lv-LV": "yyyy.MM.dd.",
            "mi-NZ": "dd/MM/yyyy",
            "mk-MK": "dd.MM.yyyy",
            "ml-IN": "dd-MM-yy",
            "mn-MN": "yy.MM.dd",
            "mn-Mong-CN": "yyyy/M/d",
            "moh-CA": "MM/dd/yyyy",
            "mr-IN": "dd-MM-yyyy",
            "ms-BN": "dd/MM/yyyy",
            "ms-MY": "dd/MM/yyyy",
            "mt-MT": "dd/MM/yyyy",
            "nb-NO": "dd.MM.yyyy",
            "ne-NP": "MM/dd/yyyy",
            "nl-BE": "dd/MM/yyyy",
            "nl-NL": "dd-MM-yyyy",
            "nn-NO": "dd.MM.yyyy",
            "nso-ZA": "yyyy/MM/dd",
            "oc-FR": "dd/MM/yyyy",
            "or-IN": "dd-MM-yy",
            "pa-IN": "dd-MM-yy",
            "pl-PL": "yyyy-MM-dd",
            "prs-AF": "dd/MM/yy",
            "ps-AF": "dd/MM/yy",
            "pt-BR": "dd/MM/yyyy",
            "pt-PT": "dd-MM-yyyy",
            "qut-GT": "dd/MM/yyyy",
            "quz-BO": "dd/MM/yyyy",
            "quz-EC": "dd/MM/yyyy",
            "quz-PE": "dd/MM/yyyy",
            "rm-CH": "dd/MM/yyyy",
            "ro-RO": "dd.MM.yyyy",
            "ru-RU": "dd.MM.yyyy",
            "rw-RW": "MM/dd/yyyy",
            "sa-IN": "dd-MM-yyyy",
            "sah-RU": "MM.dd.yyyy",
            "se-FI": "dd.MM.yyyy",
            "se-NO": "dd.MM.yyyy",
            "se-SE": "yyyy-MM-dd",
            "si-LK": "yyyy-MM-dd",
            "sk-SK": "dd. MM. yyyy",
            "sl-SI": "dd.MM.yyyy",
            "sma-NO": "dd.MM.yyyy",
            "sma-SE": "yyyy-MM-dd",
            "smj-NO": "dd.MM.yyyy",
            "smj-SE": "yyyy-MM-dd",
            "smn-FI": "dd.MM.yyyy",
            "sms-FI": "dd.MM.yyyy",
            "sq-AL": "yyyy-MM-dd",
            "sr-Cyrl-BA": "dd.MM.yyyy",
            "sr-Cyrl-CS": "dd.MM.yyyy",
            "sr-Cyrl-ME": "dd.MM.yyyy",
            "sr-Cyrl-RS": "dd.MM.yyyy",
            "sr-Latn-BA": "dd.MM.yyyy",
            "sr-Latn-CS": "dd.MM.yyyy",
            "sr-Latn-ME": "dd.MM.yyyy",
            "sr-Latn-RS": "dd.MM.yyyy",
            "sv-FI": "dd.MM.yyyy",
            "sv-SE": "yyyy-MM-dd",
            "sw-KE": "MM/dd/yyyy",
            "syr-SY": "dd/MM/yyyy",
            "ta-IN": "dd-MM-yyyy",
            "te-IN": "dd-MM-yy",
            "tg-Cyrl-TJ": "dd.MM.yy",
            "th-TH": "dd/MM/yyyy",
            "tk-TM": "dd.MM.yy",
            "tn-ZA": "yyyy/MM/dd",
            "tr-TR": "dd.MM.yyyy",
            "tt-RU": "dd.MM.yyyy",
            "tzm-Latn-DZ": "dd-MM-yyyy",
            "ug-CN": "yyyy-M-d",
            "uk-UA": "dd.MM.yyyy",
            "ur-PK": "dd/MM/yyyy",
            "uz-Cyrl-UZ": "dd.MM.yyyy",
            "uz-Latn-UZ": "dd/MM yyyy",
            "vi-VN": "dd/MM/yyyy",
            "wo-SN": "dd/MM/yyyy",
            "xh-ZA": "yyyy/MM/dd",
            "yo-NG": "dd/MM/yyyy",
            "zh-CN": "yyyy/M/d",
            "zh-HK": "dd/MM/yyyy",
            "zh-MO": "dd/MM/yyyy",
            "zh-SG": "dd/MM/yyyy",
            "zh-TW": "yyyy/M/d",
            "zu-ZA": "yyyy/MM/dd"
        };

        return formats[locale || 'en-US'];
    }

    static getDecimalPrecision = (currency, defaultPrecision) => {
        const currencies = {
            "ADP": 0,
            "AFA": 0,
            "AU5": 5,
            "BEF": 0,
            "BHD": 3,
            "BIF": 0,
            "BYB": 0,
            "CLP": 0,
            "COP": 0,
            "DEM3": 3,
            "DJF": 0,
            "ECS": 0,
            "ESP": 0,
            "GNF": 0,
            "GRD": 0,
            "HUF": 0,
            "IDR": 0,
            "IQD": 3,
            "ITL": 0,
            "JOD": 3,
            "JPY": 0,
            "KMF": 0,
            "KRW": 0,
            "KWD": 3,
            "LAK": 0,
            "LUF": 0,
            "LYD": 3,
            "MGF": 0,
            "MZM": 0,
            "OMR": 3,
            "PTE": 0,
            "PYG": 0,
            "ROL": 0,
            "RWF": 0,
            "TJR": 0,
            "TMM": 0,
            "TND": 3,
            "TPE": 0,
            "TRL": 0,
            "UGX": 0,
            "USD": 2,
            "USD3": 3,
            "USDN": 5,
            "VND": 0,
            "VUV": 0,
            "XAF": 0,
            "XOF": 0,
            "XPF": 0
        };

        if (currencies[currency] != null)
            return currencies[currency]
        else
            return defaultPrecision || 2;
    };

    static isBetween = (start, end, compare, inclusively = true) => {
        let between = false;

        try {
            if (!compare || compare == 'today')
                compare = DataUtil.parseToday();
            else if (typeof compare == 'string')
                compare = DataUtil.parseDate(compare);
            if (typeof start == 'string')
                start = DataUtil.parseDate(start);
            if (typeof end == 'string')
                end = DataUtil.parseDate(end);
            between = compare.isBetween(start, end, 'day', inclusively);
        }
        catch (e) { }

        return between;
    };

    static isAfter = (date, compare, inclusively = true) => {
        let after = false;

        try {
            if (!compare || compare == 'today')
                compare = DataUtil.parseToday();
            if (typeof date == 'string')
                date = DataUtil.parseDate(date);

            if (inclusively)
                after = compare.isSameOrAfter(date, 'day');
            else
                after = compare.isAfter(date, 'day');
        }
        catch (e) { }

        return after;
    };

    static isBefore = (date, compare, inclusively = true) => {
        let before = false;

        try {
            if (!compare || compare == 'today')
                compare = DataUtil.parseToday();
            if (typeof date == 'string')
                date = DataUtil.parseDate(date);

            if (inclusively)
                before = compare.isSameOrBefore(date, 'day');
            else
                before = compare.isBefore(date, 'day');
        }
        catch (e) { }

        return before;
    };

    static parseDate = (date, format) => {
        if (!format)
            return moment(date, DataUtil.getUserFormat().date.toUpperCase());
        return moment(date, format);
    };

    static parseToday = () => {
        return moment().startOf('day');
    };

    static getDate = (date, format, invalidDate = '') => {
        if (!format)
            format = DataUtil.getUserFormat().date;
        const mDate = moment(date);
        return mDate.isValid() ? mDate.format(format.toUpperCase()) : invalidDate;
    };

    static getDateTime = (date = new Date(), format, includeTimezone = false, invalidDate = '') => {
        //var d = new Date();
        if (!format)
            format = DataUtil.getUserFormat().date; //process.env.REACT_APP_DATETIME_FORMAT;
        const mDate = moment(date);
        return mDate.isValid() ? mDate.format(format.toUpperCase()) + " " + date.toLocaleTimeString() + (includeTimezone ? ' ' + DataUtil.getTimezoneName(date) : '') : invalidDate;
    };

    static getDateTimeShort = (date, format, invalidDate = '') => {
        if (!format)
            format = process.env.REACT_APP_DATETIME_SHORT_FORMAT;
        const mDate = moment(date);
        return mDate.isValid() ? mDate.format(format.toUpperCase()) : invalidDate;
    };

    static getTime = (date, invalidDate = '') => {
        var d = new Date(date);

        //if (!format)
        //    format = process.env.REACT_APP_TIME_FORMAT;
        const mDate = moment(date);
        return mDate.isValid() ? d.toLocaleTimeString() : invalidDate;
    };

    static getTimeShort = (date, format, invalidDate = '') => {
        if (!format)
            format = process.env.REACT_APP_TIME_SHORT_FORMAT;
        const mDate = moment(date);
        return mDate.isValid() ? mDate.format(format.toUpperCase()) : invalidDate;
    };

    static get12TimeShort = (date, format, invalidDate = '') => {
        if (!format)
            format = 'h:mm A';
        const mDate = moment(date);
        return mDate.isValid() ? mDate.format(format.toUpperCase()) : invalidDate;
    };

    static get12DateTimeShort = (date, format, invalidDate = '') => {
        if (!format)
            format = 'YYYY/MM/DD h:mm:s A';
        const mDate = moment(date);
        return mDate.isValid() ? mDate.format(format.toUpperCase()) : invalidDate;
    };

    static getTimezoneName = (date) => {
        let zone = '';
        try {
            zone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        }
        catch(e) {}
        return zone;
    };

    static formatSizeUnits = (bytes) => {
        if (bytes >= 1073741824)
            bytes = (bytes / 1073741824).toFixed(2) + ' GB';
        else if (bytes >= 1048576)
            bytes = (bytes / 1048576).toFixed(2) + ' MB';
        else if (bytes >= 1024)
            bytes = (bytes / 1024).toFixed(2) + ' KB';
        else if (bytes > 1)
            bytes = bytes + ' bytes';
        else if (bytes == 1)
            bytes = bytes + ' byte';
        else
            bytes = '0 byte';

        return bytes;
    };

    /*static fillTemplate = (templateString, templateVars, templateNotFound) => {
        if(!templateVars)
            return templateString;
        if(!templateNotFound)
            templateNotFound = '';
        try {
            var func = new Function(...Object.keys(templateVars), 'return `' + templateString + '`');
            return func(...Object.values(templateVars));
        }
        catch(e) {
            return templateNotFound
        }
    };*/

    static fillTemplate = (tmpl, data, defaultValue, reg) => {

        return tmpl?.replace(reg || /\${([^}]+)}/g/*/{([^{}]*)}}/g*/, function (a, b) {
            try {
                return new Function('data', ['with(data){return ', b, ' || \'', (defaultValue || ''), '\';}'].join(''))(data);
            }
            catch (e) {
                return defaultValue || '';
            }
        });
    };

    static generateKey = (prefix) => {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        if (!prefix)
            return `${uuid}`;
        else
            return `${prefix}_${uuid}`;
    };

    static generateId = (length, includeNumbers) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        if (includeNumbers)
            characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    };

    static removeKeyStartsWith(obj, letter) {
        Object.keys(obj).forEach(function (key) {
            //if(key[0]==letter) delete obj[key];////without regex
            if (key.match('^' + letter)) delete obj[key];//with regex
        });
        return obj;
    };

    static removeIfValue(obj, value) {
        Object.keys(obj).forEach(function (key) {
            //if(key[0]==letter) delete obj[key];////without regex
            if (obj[key] == value) delete obj[key];//with regex
        });
        return obj;
    };

    static allValues(obj, value) {
        let matches = true;
        Object.keys(obj).forEach(function (key) {
            if (obj[key] != value)
                matches = false;
        });
        return matches;
    };

    static extractFields(obj, fields) {
        const newObj = {};
        fields.map(f => { newObj[f] = obj[f] });
        return newObj
    };

    static extractArrayFields(obj) {
        const newObj = {};
        Object.keys(obj).forEach(function (key) {
            if (Array.isArray(obj[key]))
                newObj[key] = obj[key];
        });
        return newObj
    };

    static extractArrayField(obj, fieldName) {
        for (var key in obj) {
            const field = obj[key];
            if (Array.isArray(field) && field?.[0]?.hasOwnProperty(fieldName) == true)
                return { key, field };
        }
        return { key: null, field: null };
    };

    static stringifyValues(obj) {
        Object.keys(obj).forEach(function (key) {
            if (typeof (obj[key]) == 'number')
                obj[key] = obj[key].toString();
        });
        return obj;
    };

    static avatarImage(objType) {
        if (objType == 'P')
            return avatarEmp;
        else if (objType == 'S')
            return avatarPos;
        else if (objType == 'O')
            return avatarOrg;
    };

    //group or decimal
    static getSeparator(locale, separatorType) {
        const numberWithGroupAndDecimalSeparator = 10000.1;
        return Intl.NumberFormat(locale)
            .formatToParts(numberWithGroupAndDecimalSeparator)
            .find(part => part.type === separatorType)
            .value;
    }

    static convertNumber(value, decimals, currency) {
        try {
            //console.log(moment.locale());
            if (value == null)
                return value;

            if (decimals)
                decimals = parseInt(decimals, 10);

            let locale = navigator.language || 'en-US';

            if (currency) {
                if (decimals) {
                    if (currency === "USD3" || currency === "USDN")
                        currency = "USD";
                    return new Intl.NumberFormat(locale, { style: 'currency', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals, currency: currency }).format(value);
                }
                else {
                    if (currency === "USD3" || currency === "USDN") {
                        const usdCurrency = "USD";
                        let usdDecimals = 0;

                        if (currency === "USD3")
                            usdDecimals = 3;
                        else if (currency === "USDN")
                            usdDecimals = 5;
                        return new Intl.NumberFormat(locale, { style: 'currency', currencyDisplay: 'narrowSymbol', minimumFractionDigits: usdDecimals, currency: usdCurrency }).format(value);
                    }
                    else
                        return new Intl.NumberFormat(locale, { style: 'currency', currencyDisplay: 'narrowSymbol', currency: currency }).format(value);
                }
            }
            else if (decimals)
                return new Intl.NumberFormat(locale, { minimumFractionDigits: decimals }).format(value);
            else
                return new Intl.NumberFormat(locale).format(value);
        }
        catch (e) {
            console.log(e);
            return value;
        }
    };

    static sortByProperty = (property, caseInsensitive) => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            if (caseInsensitive) {
                var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
                return result * sortOrder;
            }
            else {
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }
    };

    static getStatusColor = (status) => {
        switch (status) {
            case 'BACK':
                return '#400095';
            case 'CMP_APPR':
                return '#26ace2';
            case 'FIN_MGR':
                return '#74c25c';
            case 'HROPS':
                return '#ce158b';
            case 'HROPS_USH':
                return '#0368d9';
            case 'PAYROLL_ADP':
                return '#f36d38';
            case 'PAYROLL_SAP':
                return '#f36d38';
            case 'HROPS_INTL':
                return '#deb932';
            case 'DRAFT':
                return '#a7a9ac';
            case 'COMPLETED':
                return '#896eb1';
            case 'ERROR':
                return '#cc3333';
            case 'CANCELED':
                return '#eeeeee';
            case 'DEFAULT':
                return '#0f5689';
            case 'PAYROLL':
                return '#B640BD';
            case 'COMP_UO':
                return '#26ace2';
            case 'HR_UO':
                return '#152B76';
            default: //should never render this color
                return '#33FF5B';
        }
    };

    static getLmsColor = (status) => {
        switch (status) {
            case 'ASSIGNED':
                return '#76c9e3';
            case 'DUEIN14':
                return '#5ba1c0';
            case 'OVERDUE':
                return '#386e85';
            default: //should never render this color
                return '#333333';
        }
    };

    static getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    static isChecked(value) {
        return value == 'X' || value == true;
    };

    static getButtonIcon = (code) => {
        switch (code) {
            case 'BTN_CHECK':
                return 'fa fa-check-circle';
            case 'BTN_SAVE_DRAFT':
                return 'fa fa-save';
            case 'BTN_CANCEL':
                return 'fa fa-times';
            case 'BTN_DELETE_DRAFT':
                return 'fa fa-trash-alt';
            case 'BTN_SAVE_DRAFT':
                return 'fa fa-save';
            case 'BTN_SUBMIT':
                return 'fa fa-check';
            case 'BTN_EDIT':
                return 'fa fa-edit';
            case 'BTN_WITHDRAW':
                return 'fa fa-ban';
            case 'BTN_APPROVE':
                return 'fa fa-check';
            case 'BTN_BACK_TO_AUTHOR':
                return 'fa fa-undo';
            case 'BTN_BACK_TO_USER':
                return 'fa fa-undo';
            case 'BTN_VIEWHIST':
                return 'fa fa-history';
            case 'BTN_SEND':
                return 'fa fa-check';
            default: //no icon
                return '';
        }
    };

    static clone = (object) => {
        return JSON.parse(JSON.stringify(object));
    };

    static getI9ClassName = (status) => {
        switch (status) {
            case 'Terminated':
                return 'brc-red-l1';
            case 'EVerifyComplete':
                return 'brc-green-l1';
            case 'Section1Complet':
                return 'brc-green-l1';
            case 'EVerifyPending':
                return 'brc-yellow-l1';
            case 'Section1Pending':
                return 'brc-yellow-l1';
            case 'EVerifyCancelle':
                return 'brc-red-l1';
            case 'FormI9Cancelled':
                return 'brc-red-l1';
            case 'FormI9Completed':
                return 'brc-green-l1';
            default: //no icon
                return '';
        }
    };

    static getI9Icon = (status) => {
        switch (status) {
            case 'Terminated':
                return 'fa fa-times-circle';
            case 'EVerifyComplete':
                return 'fa fa-check-circle';
            case 'Section1Complet':
                return 'fa fa-check-circle';
            case 'EVerifyPending':
                return 'fa fa-clock';
            case 'Section1Pending':
                return 'fa fa-clock';
            case 'EVerifyCancelle':
                return 'fa fa-times-circle';
            case 'FormI9Cancelled':
                return 'fa fa-times-circle';
            case 'FormI9Completed':
                return 'fa fa-check-circle';
            default: //no icon
                return '';
        }
    };
}