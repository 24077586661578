import React from "react";
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { withNamespaces, Trans } from "react-i18next";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import jwt from 'jsonwebtoken';
import * as actions from "../../store/actions/actions";

import logo from "../../assets/image/nbcu-logo.png";
import DataUtil from "../Utils/DataUtil";
import { getSystemConfig, logout } from "../Services/Interceptors/Token";
import { Image } from "../MDF/UIComponents/internal";
import AppStateManager from "../../AppStateManager";
import { hasAnyAuthorities } from "../Services/Guards/auth-guard";

class Navbar extends React.Component {
  render() {
    const { t } = this.props;
    const token = jwt.decode(localStorage.getItem('access_token'));
    const system = AppStateManager.getSystem();//getSystemConfig();
    return (
      <nav className="navbar navbar-expand-lg navbar-fixed navbar-blue">
        <div className="navbar-inner">
          <div className="navbar-intro justify-content-xl-between">
            <button type="button" className="btn btn-burger burger-arrowed static collapsed ml-2 d-flex d-xl-none" data-toggle-mobile="sidebar" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle sidebar">
              <span className="bars"></span>
            </button>{/* mobile sidebar toggler button */}
            <button type="button" className="btn btn-burger ml-35 d-none d-xl-flex" data-toggle="sidebar" data-target="#sidebar" aria-controls="sidebar" aria-expanded="true" aria-label="Toggle sidebar">
              <span className="bars"></span>
            </button>{/* sidebar toggler button */}
            <Link to="/hrd/home" className="navbar-brand text-white">
              <span><img src={logo} className="ml-2 wlx-header-logo" alt="Home" /></span>
            </Link>{/* /.navbar-brand */}
          </div>{/* /.navbar-intro */}
          <div className="navbar-content">
          </div>{/* .navbar-content */}
          {
            system?.enableRedirect
            ? (
              hasAnyAuthorities(["PORTAL_ALL", "SYSTEM_ALL", "SYSTEM_PAGE"])
              ? (
                <Link to="/portal/system" className="nav-link bgc-danger-d2 pl-lg-3 text-white">
                  <i className="fas fa-cog fa-spin fa-2x mt-15"></i>
                </Link>
              )
              : (
                <Link to="#" className="nav-link bgc-danger-d2 pl-lg-3 text-white" style={{pointerEvents: 'none'}}>
                  <i className="fas fa-cog fa-spin fa-2x mt-15"></i>
                </Link>
              )
            )
            : null
          }
          {/* mobile #navbarMenu toggler button */}
          <button className="navbar-toggler ml-1 mr-2 px-1" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navbar menu">
            {/*<span className="pos-rel">
              <img className="border-2 brc-white-tp1 radius-round" width="36" src="assets/image/avatar/avatar6.jpg" alt="Jason's Photo" />
              <span className="bgc-warning radius-round border-2 brc-white p-1 position-tr mr-n1px mt-n1px"></span>
            </span>*/}
            <Image data={{ Pernr: token?.user?.username }} component={{ properties: { fallback: "INITIALS", cssClassName: "profile-bubble-xs", imageUrl: process.env.REACT_APP_PROFILE_PICTURE + "${Pernr}.jpg" } }}>
              <div className="align-items-center pt-2 mx-2px border-2 w-6 h-6 radius-round bgc-primary text-white">
                <h4 className="w-100 font-bold text-center">{DataUtil.getInitial(token?.user?.firstName)}{DataUtil.getInitial(token?.user?.lastName)}</h4>
              </div>
            </Image>
          </button>
          <div className="navbar-menu collapse navbar-collapse navbar-backdrop" id="navbarMenu">
            <div className="navbar-nav">
              <ul className="nav">
                <li className="nav-item dropdown order-first order-lg-last">
                  <a className="nav-link dropdown-toggle" data-toggle="dropdown" href={void (0)} role="button" aria-haspopup="true" aria-expanded="false">
                    <Image data={{ Pernr: token?.user?.username }} component={{ properties: { fallback: "INITIALS", cssClassName: "profile-bubble-xs", imageUrl: process.env.REACT_APP_PROFILE_PICTURE + "${Pernr}.jpg" } }}>
                      <div className="align-items-center pt-2 pl-3px ml-3px mr-2 border-2 w-6 h-6 radius-round bgc-primary text-white brc-white-tp1">
                        <h4 className="w-100 font-bold text-center">{DataUtil.getInitial(token?.user?.firstName)}{DataUtil.getInitial(token?.user?.lastName)}</h4>
                      </div>
                    </Image>
                    <span className="d-inline-block d-lg-none d-xl-inline-block text-white">
                      <span id="id-user-welcome">{t("welcome")},</span>
                      <span className="nav-user-name">{token?.user?.firstName}</span>
                    </span>
                    <i className="caret fa fa-angle-down d-none d-xl-block"></i>
                    <i className="caret fa fa-angle-left d-block d-lg-none text-white"></i>
                  </a>
                  <div className="dropdown-menu dropdown-caret dropdown-menu-right dropdown-animated brc-primary-m3 py-1">
                    <div className="d-none d-lg-block d-xl-none">
                      <div className="dropdown-header">
                        {t("welcome")}, {token?.user?.firstName}
                      </div>
                      <div className="dropdown-divider"></div>
                    </div>
                    <Link to="/portal/settings" className="dropdown-item">
                      <i className="fa fa-cog text-105 mr-1"></i>
                      {t("settings")}
                    </Link>
                    <div className="dropdown-divider brc-primary-l2"></div>
                    <a href={void (0)} onClick={logout} className="dropdown-item">
                      <i className="fa fa-sign-out-alt text-105 mr-1"></i>
                      {t("logout")}
                    </a>
                  </div>
                </li>{/* /.nav-item:last */}
              </ul>{/* /.navbar-nav menu */}
            </div>{/* /.navbar-nav */}
          </div>{/* /#navbarMenu */}
        </div>{/* /.navbar-inner */}
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default compose(withNamespaces("common"), connect(mapStateToProps, mapDispatchToProps))(withRouter(AppStateManager.subscribe(Navbar)));
