import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withNamespaces, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import sweetalert from 'sweetalert';
import Swal from '../Common/Swal';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import { logout, refreshToken } from '../Services/Interceptors/Token';
import AppStateManager from '../../AppStateManager';
//import { toggleSetting } from '../../store/reducers/settings.slice';

class Footer extends Component {

  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.state = {
      idleTime: 1620000, //27 min * 60 seconds * 1000 ms
      timeout: 120, // in Seconds
      countdown: new Date(),
      beforeCountdown: new Date(),
      refreshTimeout: 1500, // in Seconds
      refreshCountDown: new Date(),
      beforeRefreshCountDown: new Date(),
      showWarningDialog: false
    };
  }

  componentDidMount() {
    this.beginRefreshCountDown();
  }

  componentWillUnmount() {
    clearInterval(this.countdownInterval);
    clearInterval(this.refreshInterval);
  }

  performAutoSave = () => {
    const form = AppStateManager.getForm();
    const autosave = form != null && !form?.isInfoTypeForm() && form?.isInitiate();

    return autosave;
  }

  countdownTimer = () => {
    this.setState(
      {
        countdown: new Date(),
      },
      () => {

        const elapsedTime = Math.floor((this.state.countdown.getTime() - this.state.beforeCountdown.getTime()) / 1000);
        if (elapsedTime >= this.state.timeout) {
          this.openSessionExpired();
        } else {
          this.openSessionTimeout();
        }
      },
    );
  };

  refreshTimer = () => {
    this.setState(
      {
        refreshCountDown: new Date(),
      },
      () => {
        const elapsedTime = Math.floor((this.state.refreshCountDown.getTime() - this.state.beforeRefreshCountDown.getTime()) / 1000);
        //console.log('refresh elapsed', elapsedTime);
        if (elapsedTime >= this.state.refreshTimeout) {
          refreshToken();
          this.setState({ beforeRefreshCountDown: new Date() });
        }
      },
    );
  };

  beginCountdown = () => {
    this.setState(
      {
        beforeCountdown: new Date(),
      },
      () => {
        this.countdownInterval = setInterval(this.countdownTimer, 1000);
      });
  };

  beginRefreshCountDown = () => {
    this.refreshInterval = setInterval(this.refreshTimer, 1000);
  };

  openSessionExpired = async () => {
    const { t } = this.props;
    const autosave = this.performAutoSave();
    //if (!this.state.showWarningDialog) {
    clearInterval(this.refreshInterval); //no need to refresh the token anymore
    clearInterval(this.countdownInterval);

    // save
    if(autosave)
      await this.save();

    sweetalert({
      title: t("sessionExpired"),
      text: autosave ? t("savedAndSessionExpiredMessage") : t("sessionExpiredMessage"),
      icon: 'error',
      closeModal: true,
      button: t("ok"),
    }).then(() => {
      //this.props.loading();
      this.setState({ showWarningDialog: true });
      logout();
    });
    //}
  };

  openSessionTimeout = () => {
    const { t } = this.props;
    if (!this.state.showWarningDialog) {
      const elapsedTime = Math.floor((this.state.countdown.getTime() - this.state.beforeCountdown.getTime()) / 1000);
      const buttons = this.performAutoSave()
        ? {
          logout: {
            text: t("logMeOut"),
            value: 0,
            closeModal: true,
            className: 'swal-button--cancel',
          },
          saveAndLogout: {
            text: t("saveAndLogMeOut"),
            value: 1,
            closeModal: true,
            className: 'swal-button--cancel',
          },
          confirm: {
            text: t("continueWorking"),
            value: 2,
          },
        } 
        : {
          logout: {
            text: t("logMeOut"),
            value: 0,
            closeModal: true,
            className: 'swal-button--cancel',
          },
          confirm: {
            text: t("continueWorking"),
            value: 2,
          },
        };
      sweetalert({
        title: t("sessionWarning", { count: this.state.timeout - elapsedTime }),
        text: t("sessionWarningMessage"),
        icon: 'warning',
        buttons: buttons
      }).then(async (selection) => {
        if (selection == 2) {
          clearInterval(this.countdownInterval);
          this.idleTimer.reset();
          this.setState({ beforeCountdown: new Date() });
          sweetalert(t("sessionRestored"), t("sessionRestoredMessage"), 'success');
        } else if (selection == 1) {
          this.setState({ showWarningDialog: true });
          await this.save();
          logout();
        } else {
          //this.props.loading();
          this.setState({ showWarningDialog: true });
          logout();
        }
      });
    }
  };

  save = async () => {
    const form = AppStateManager.getForm();
    const formioRenderer = form.formioFormRef
    const formio = formioRenderer.getFormio().formio;
    const draftButton = formio.getComponent(form.getDraftButton());
    const response = await formioRenderer.onCustomEventTrigger(draftButton);
    //const later = (delay, value) => new Promise(resolve => setTimeout(resolve, delay, value));
    //const response = await later(10000, 'data!');
    console.log('response', response);
    //logout();
  };

  render() {

    const { t } = this.props;
    //console.log(t);
    const year = new Date().getFullYear();
    return (
      <footer className="footer-container">
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          events={['keydown', 'mousedown']}
          onIdle={this.beginCountdown}
          stopOnIdle
          timeout={this.state.idleTime} //min * seconds * 1000
        />
        <span className="d-flex justify-content-center"><span dangerouslySetInnerHTML={{ __html: t('footer') }} />&nbsp;{year}</span>
        <Swal></Swal>
      </footer>
    );
  }
}

Footer.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces('footer')(withRouter(Footer)));

