import React, { Component } from "react";
import ReactDOM from "react-dom";
import i18next from 'i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { ReactComponent } from "react-formio";
import settingsForm from "./SearchPopup.settingsForm";
import { View } from "../../../view/View";

import $ from 'jquery';
import ViewService from "../../../../../Services/ViewService";
import DataUtil from "../../../../../../Utils/DataUtil";
import UiUtil from "../../../../../../Utils/UiUtil";

import moment from 'moment';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const CustomComp = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            values: null, // used for multiSelect
            openModal: false,
            viewDefinition: null,
            selectionProperties: null,
            formData: { criteria: '' },
            criteriaMinLength: 0,
            viewName: '',
            params: { params: JSON.stringify({ FieldName: '', Criteria: '' }) },
            showView: false
        };
    };

    setValue = (value) => {
        this.setState({ value }, () => this.props.onChange(this.state.value, null));
    };

    handleChange = (e) => {
        this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } });
    };

    toggleModal = () => {
        this.setState({ openModal: !this.state.openModal, showView: false });
    };

    isMultiSelect = () => {
        let multiSelect = false;
        if (this.state.viewDefinition)
            multiSelect = this.state.viewDefinition?.properties?.multiSelect == true;
        else { // on load flow comes here
            const component = this.props.component;
            const element = component._element;
            const properties = element.properties;
            const formio = this.props.formioComponent._currentForm;
            const data = formio?._data || {};

            try {
                multiSelect = Array.isArray(JSON.parse(data?.[properties?.textField]));
            }
            catch (e) { }
            /*const emptyText = DataUtil.fillTemplate(properties.textFieldTemplate, null);
            const text = DataUtil.fillTemplate(properties.textFieldTemplate, data);
            console.log(component.key, this._data?.[properties?.textField])*/
        }
        return multiSelect;
    };

    clear = () => {
        const component = this.props.component;
        const element = component._element;
        const formio = this.props.formioComponent._currentForm;
        const c = formio.getComponent(component.key);

        c.updateValue(''); // this will trigger formio for change
        //c._data[component.key] = radio.val();
        c._data[element.properties.textField] = '';

        this.setState({ selectionProperties: null });
    };

    openSearch = async () => {
        const component = this.props.component;
        const element = component._element;

        if (!this.state.viewDefinition) {
            const response = await new ViewService().getViewByUrl(element.properties.url, {});
            const viewDefinition = response.data;

            this.setState({ viewDefinition }, this.toggleModal);
        }
        else
            this.toggleModal();
    };

    criteriaKeyDown = (e) => {
        if (e.key === 'Enter')
            this.triggerSearch(e);
    };

    triggerSearch = async (e) => {
        const viewDefinition = this.state.viewDefinition;
        const criteriaMinLength = viewDefinition.properties?.criteriaMinLength || 0;
        const criteria = this.state.formData.criteria;

        if (criteria.length < criteriaMinLength)
            UiUtil.showToast(i18next.t("form:searchPopupCriteriaMinLength", { criteriaMinLength }));
        else {
            const component = this.props.component;
            const element = component._element;
            const formio = this.props.formioComponent._currentForm;
            const data = formio?._data;
            const cFormFields = element.properties.formFields || [];
            const viewName = viewDefinition.code;
            const fieldName = viewDefinition.properties.fieldName;
            const vFormFields = viewDefinition.properties.formFields?.split(',') || [];
            const additionalParams = {};

            cFormFields.map(f => additionalParams[f] = data[f]);
            vFormFields.map(f => {
                const c = formio.getComponent(f);
                console.log('c', c);
                if(c) { // if the view's configured formField is not found in the current form, skip it
                    if (c.type == 'datetime') {
                        if(additionalParams[f] == 'Invalid date')
                            additionalParams[f] = '';
                        else if(additionalParams[f] != '')
                            additionalParams[f] = moment(data[f]).format(process.env.REACT_APP_SERVICE_DATE_FORMAT.toUpperCase());
                    }
                    else
                        additionalParams[f] = data[f];
                }
            });

            const params = { params: JSON.stringify({ FieldName: fieldName, Criteria: criteria?.replaceAll("'", "''"), ...additionalParams }) };

            this.setState({ showView: false }, () => { this.setState({ showView: true, viewName, params }); });
        }
    };

    selectResult = () => {
        const viewDefinition = this.state.viewDefinition;
        const component = this.props.component;
        const element = component._element;
        const formio = this.props.formioComponent._currentForm;
        const view = ReactDOM.findDOMNode(this.viewRef);

        if (this.isMultiSelect()) {
            const delimiter = viewDefinition.properties?.delimiter || '~';
            const checkboxes = $(view).find(':checkbox:checked');

            if (checkboxes.length) {
                const c = formio.getComponent(component.key);
                let keys = [], values = [], selectionProperties;

                // add currently saved values
                if (c._data[component.key]) {
                    keys = c._data[component.key].split(delimiter);
                    values = this.getValues();
                }

                // add newly selected values
                checkboxes.map(index => {
                    const checkbox = $(checkboxes[index]);
                    const key = checkbox.val();
                    const value = JSON.parse(checkbox.attr('data'));
                    selectionProperties = JSON.parse(checkbox.attr('properties'));

                    if (viewDefinition.properties?.unique != true || !keys.includes(key)) {
                        keys.push(key);
                        values.push(value);
                    }
                });

                /*this.setValue(keys.join(delimiter));
                //formio.getComponent(component.key).getWidget().setValue(keys.join(delimiter));
                formio.getComponent(component.key).updateValue(keys.join(delimiter));
                //formio.getComponent(element.properties.textField).updateValue(JSON.stringify(values));
                formio.getComponent(element.properties.textField)._data[element.properties.textField] = JSON.stringify(values);
                */
                c.updateValue(keys.join(delimiter)); // this will trigger formio for change
                //c._data[component.key] = keys.join(delimiter);
                c._data[element.properties.textField] = JSON.stringify(values);

                this.setState({ selectionProperties, values });

                this.toggleModal();
            }
            else
                UiUtil.showToast(i18next.t("form:searchPopupSelectMessage"));
        }
        else {
            const radio = $(view).find(':radio:checked');

            if (radio.length) {
                const c = formio.getComponent(component.key);
                const data = JSON.parse(radio.attr('data'));
                const selectionProperties = JSON.parse(radio.attr('properties'));

                /*this.setValue(radio.val());
                formio.getComponent(component.key).updateValue(radio.val());
                //formio.getComponent(element.properties.textField).updateValue(data[selectionProperties.text]);
                formio.getComponent(element.properties.textField)._data[element.properties.textField] = data[selectionProperties.text];
                */
                c.updateValue(radio.val()); // this will trigger formio for change
                //c._data[component.key] = radio.val();
                c._data[element.properties.textField] = data[selectionProperties.text];

                this.setState({ selectionProperties });

                this.toggleModal();
            }
            else
                UiUtil.showToast(i18next.t("form:searchPopupSelectMessage"));
        }
    };

    removeResult = async (value) => {
        const selectionProperties = await this.getSelectionProperties();
        const viewDefinition = this.state.viewDefinition;
        const component = this.props.component;
        const element = component._element;
        const formio = this.props.formioComponent._currentForm;
        const data = formio?._data;
        const delimiter = viewDefinition?.properties?.delimiter || '~';
        const keys = data[component.key].split(delimiter);
        const values = this.getValues();
        const key = selectionProperties.value.replace('${', '').replace('}', '');
        const newKeys = keys.filter(k => k != value[key]).join(delimiter);
        const newValues = values.filter(k => k[key] != value[key]);

        /*this.setValue(newKeys);
        formio.getComponent(component.key).updateValue(newKeys);
        //formio.getComponent(element.properties.textField).updateValue(JSON.stringify(newValues));
        formio.getComponent(element.properties.textField)._data[element.properties.textField] = newValues.length ? JSON.stringify(newValues) : '';
        */
        const c = formio.getComponent(component.key);
        c.updateValue(newKeys);
        //c._data[component.key] = newKeys;
        c._data[element.properties.textField] = newValues.length ? JSON.stringify(newValues) : '';

        this.setState({ values: newValues });
    };

    getSelectionProperties = async () => {
        let selectionProperties = this.state.selectionProperties;
        if (!selectionProperties) {
            const formioComponent = this.props.formioComponent;
            formioComponent?.setLoading(formioComponent?.element?.getElementsByClassName('col-form-label')?.[0], true);

            const component = this.props.component;
            const element = component._element;
            const response = await new ViewService().getViewByUrl(element.properties.url, {});
            const viewDefinition = response.data;
            const elements = viewDefinition.elements.find(e => e.type == 'TABLE').elements.find(e => e.code == 'COL_SELECTION').elements;
            if (elements?.length == 1)
                selectionProperties = elements[0]?.properties;
            else
                selectionProperties = elements.find(e => e.code == 'Selection' || e.type == 'radio' || e.type == 'checkbox')?.properties;
            this.setState({ selectionProperties });
            formioComponent?.setLoading(formioComponent?.element?.getElementsByClassName('col-form-label')?.[0], false);
        }
        return selectionProperties;
    };

    getValues = () => {
        const component = this.props.component;
        const element = component._element;
        const properties = element.properties;
        const formio = this.props.formioComponent._currentForm;
        const data = formio?._data || {};
        let values = [];
        if (this.state.values)
            values = this.state.values
        else {
            try {
                values = JSON.parse(data?.[properties?.textField]);
            }
            catch (e) { }
        }
        return values;
    };

    componentDidMount() {
        //console.log('componentDidMount', '************** SEARCH POPUP =============>');
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => { return; };
    };

    render() {
        //console.log(this.props.component)
        const viewDefinition = this.state.viewDefinition;
        const component = this.props.component;
        const element = component._element;
        const properties = element.properties;
        const formio = this.props.formioComponent._currentForm;
        const data = formio?._data || {};
        let emptyText = '', text = '', values = [];

        if (!this.isMultiSelect()) {
            emptyText = DataUtil.fillTemplate(properties.textFieldTemplate, null);
            text = DataUtil.fillTemplate(properties.textFieldTemplate, data);
        }
        else {
            if (this.state.values)
                values = this.state.values
            else {
                try {
                    values = JSON.parse(data?.[properties?.textField]);
                }
                catch (e) { }
            }
        }

        return (
            <>
                <div className={!component.disabled && text != emptyText ? "input-group search-control search-control-has-clear" : "input-group search-control"} ref="element">
                    <input id={`${component.id}-${component.key}`} defaultValue={this.state.value} className="form-control bg-white" type="hidden" name={`data[${component.key}]`} ref="input" />
                    {
                        this.isMultiSelect()
                            ? <Tags component={component} values={values} removeResult={this.removeResult} />
                            : <div id={`${component.id}-${component.key}-LABEL`} className={component.disabled ? "form-control d-table disabled" : "form-control bg-white d-table border-right-0"}>{text == emptyText ? '' : text}</div>
                    }
                    {
                        component.disabled
                            ? null
                            : (
                                <>
                                    {
                                        (text == emptyText)
                                            ? null
                                            : (
                                                <span className="input-group-addon bootstrap-touchspin-postfix input-group-append">
                                                    <span className="input-group-text bg-white">
                                                        <button type="button" className="close text-115 font-medium" onClick={this.clear}>
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </span>
                                                </span>
                                            )
                                    }
                                    <span className="input-group-addon input-group-append pointer" onClick={this.openSearch}>
                                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                                    </span>
                                </>
                            )
                    }
                </div>
                <Modal style={{ maxWidth: '800px', width: '95%' }} isOpen={this.state.openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>{properties?.label}</ModalHeader>
                    <ModalBody>
                        <div className="alert text-90 brc-purp-l1 text-grey-d3" role="alert">
                            <i className="fa fa-info-circle mr-2 text-purp"></i> {viewDefinition?.properties?.helpText}
                        </div>
                        <div className="row justify-content-md-center">
                            <div className="col-8 col-xs-12">
                                <div className="row my-35">
                                    <div className="col-sm-3 col-form-label text-sm-right pr-0">
                                        <label className="mb-0">{properties?.label}</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="input-group">
                                            <input type="text" name="criteria" value={this.state.formData.criteria} className="form-control form-control-purp" onChange={this.handleChange} onKeyDown={this.criteriaKeyDown} />
                                            <div className="input-group-addon input-group-append pointer" onClick={this.triggerSearch}>
                                                <div className="input-group-text"><i className="fa fa-search"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive-md">
                            {
                                this.state.showView
                                    ? <View id={this.state.viewName} params={this.state.params} ref={ref => { this.viewRef = ref; }} options={{ table: { datatable: UiUtil.getDataTableConfig() } }} />
                                    : null
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.selectResult}>{i18next.t("form:searchPopupSelect")}</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
};

const Tags = ({ component, values, removeResult }) => {
    const element = component._element;
    const properties = element.properties;
    return (
        <div id={`${component.id}-${component.key}-LABEL`} className={component.disabled ? "form-control bootstrap-tagsinput d-table disabled" : "form-control bg-white bootstrap-tagsinput d-table"}>
            {
                values?.map(value => {
                    return <span className="tag badge badge-primary font-normal" key={DataUtil.generateKey(`${component.id}-${component.key}`)}>{DataUtil.fillTemplate(properties.textFieldTemplate, value)}{component.disabled ? null : <span data-role="remove" onClick={() => { removeResult(value) }}></span>}</span>;
                })
            }
        </div>
    );
};

export default class SearchPopup extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: "SearchPopup",
            icon: "square",
            group: "Data",
            documentation: "",
            weight: -10,
            schema: SearchPopup.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: "searchPopupCustomComp",
            label: "Default Label"
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        return ReactDOM.render(
            <CustomComp
                formioComponent={this}
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                ref={ref => { this.componentRef = ref; }}
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }

    isMultiSelect = () => {
        return this.componentRef?.isMultiSelect() || false;
    };

    /* custom function */
    getText = (delimter = ', ') => {
        const component = this.component;
        const element = component._element;
        const properties = element.properties;
        const data = this?._data || {};
        const emptyText = DataUtil.fillTemplate(properties.textFieldTemplate, null);
        const text = DataUtil.fillTemplate(properties.textFieldTemplate, data);

        if (this.isMultiSelect()) {
            let values = [];
            try {
                if (data?.[properties?.textField])
                    values = JSON.parse(data?.[properties?.textField]);
            }
            catch (e) { }
            return values.map(v => {
                const vtext = DataUtil.fillTemplate(properties.textFieldTemplate, v);
                return emptyText == vtext ? '' : vtext;
            }).join(delimter);
        }
        else
            return emptyText == text ? '' : text;
    };

    getTextArray = () => {
        const component = this.component;
        const element = component._element;
        const properties = element.properties;
        const data = this?._data || {};
        const emptyText = DataUtil.fillTemplate(properties.textFieldTemplate, null);
        console.log(component.key, this._data?.[properties?.textField])

        if (this.isMultiSelect()) {
            let values = [];
            try {
                if (data?.[properties?.textField])
                    values = JSON.parse(data?.[properties?.textField]);
            }
            catch (e) { }
            return values.map(v => {
                const vtext = DataUtil.fillTemplate(properties.textFieldTemplate, v);
                DataUtil.fillTemplate(properties.textFieldTemplate, v);
                return emptyText == vtext ? '' : vtext;
            });
        }
        else
            return null;
    };
}
