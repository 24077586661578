import React from 'react';
import DataUtil from '../../../../Utils/DataUtil';
import { Component } from '../../internal';

export class Label extends Component {

  draw() {
    const listeners = this.props.listeners;
    const component = this.props.component;
    const testEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
    const key = component?.properties?.value;
    const className = component?.properties?.cssClassName;
    const data = this.props.data;
    const attributes = {};
    let value = '';

    try {
      value = DataUtil.fillTemplate(key, data);
    } catch (e) { }

    if (className)
      attributes.className = className;

    if (value && testEmail.test(value)) {
      const href = `mailto:${value}`;
      return React.createElement('a', { ...attributes, href }, value)
    }

    attributes['dangerouslySetInnerHTML'] = {
      __html: value
    }

    if(listeners?.formatter)
      return listeners.formatter(this, data, component, value, React.createElement('span', attributes), attributes);
    else
      return React.createElement('span', attributes);
  };
}