import $ from 'jquery';
import { DateRangePicker } from "tiny-date-picker/dist/date-range-picker.js";
import TinyDatePicker from "tiny-date-picker/dist/tiny-date-picker.js";
import sweetalert from 'sweetalert';
import { toast } from 'react-toastify';
import DataUtil from './DataUtil';
import i18next from 'i18next';

export default class UiUtil {

    static initTabToggle = () => {
        $('a[data-toggle="tab"]').on('click', function () {
            $('a[data-toggle="tab"]').removeClass('active');
        });
        /*$('button[data-toggle="tab"]').on('click', function() {
            $('button[data-toggle="tab"]').removeClass('active');
        });*/
    };

    static initFormFields = () => {
        // check if we can call AceApp.Basic._handleOther() instead of code below
        document.querySelectorAll('.input-floating-label input').forEach(function (inp) {
            if (inp.value !== '') inp.classList.add('has-content'); else inp.classList.remove('has-content');
        });
        /*document.querySelectorAll('.input-floating-label select').forEach(function (select) {
            if (select.value !== '') select.classList.add('has-content');else select.classList.remove('has-content');
        });*/
    };

    static showLoading = async (title, content, icon = 'info') => {
        this.sweetalert = await sweetalert({
            title: title,
            text: content,
            icon: icon,
            closeOnClickOutside: false,
            closeModal: false,
            closeOnEsc: false,
            buttons: false
        });
    };

    static hideLoading = () => {
        sweetalert?.close();
    };

    static showAlert = (config, confirmCallback) => {
        this.sweetalert = sweetalert(config).then((result) => { if (typeof (confirmCallback) == 'function') confirmCallback(result) });
    };

    static gridColSize = (totalCols, isFirst, lastCol = 0) => {
        const availableCols = 12 - lastCol;
        if (isFirst)
            return Math.floor(availableCols / totalCols) + availableCols % totalCols;
        else
            return Math.floor(availableCols / totalCols);
    };

    static showToast = (message) => {
        toast(message);
    };

    static initDatePicker = ({ inputSelector, onChange }) => {
        var dp = TinyDatePicker(inputSelector, { mode: 'dp-below', lang: { ...i18next.t('common:datepicker') } }).on('statechange', function (ev, dp) {
            if (typeof onChange == 'function')
                onChange(ev, dp);
        });
        $(inputSelector).parents('.input-group:first').find('.fa-calendar').parent().click(function (ev) {
            dp.open();
        });
    };

    static initDateRangePicker = ({ rangeCntrSelector, rangeWrapperSelector, rangeFromSelector, rangeToSelector, onChange }) => {
        // date range picker example
        var daterange_container = document.querySelector(rangeCntrSelector);
        // Inject DateRangePicker into our container
        var drp = DateRangePicker(daterange_container, { startOpts: { mode: 'dp-below', lang: { ...i18next.t('common:datepicker') } }, endOpts: { mode: 'dp-below', lang: { ...i18next.t('common:datepicker') } } }).on('statechange', function (_, rp) {
            // Update the inputs when the state changes
            var range = rp.state;
            $(rangeFromSelector).val(range.start ? DataUtil.getDate(range.start) : '');
            $(rangeToSelector).val(range.end ? DataUtil.getDate(range.end) : '');
            if (typeof onChange == 'function')
                onChange(range, _, rp, { rangeCntrSelector, rangeWrapperSelector, rangeFromSelector, rangeToSelector, onChange });
        });

        $(`${rangeFromSelector}, ${rangeToSelector}`).on('focus', function () {
            daterange_container.classList.add('visible');
        });

        var daterange_wrapper = document.querySelector(rangeWrapperSelector);
        var previousTimeout = null;
        $(daterange_wrapper).on('focusout', function () {
            if (previousTimeout) clearTimeout(previousTimeout);
            previousTimeout = setTimeout(function () {
                if (!daterange_wrapper.contains(document.activeElement)) {
                    daterange_container.classList.remove('visible');
                }
            }, 10);
        });

        return drp;
    };

    static getDataTableConfig = () => {

        return {
            lengthMenu: [10, 25, 50],
            language: { ...i18next.t('common:datatable') }
        };
    };

    static getDataTableExportDom = () => {
        return 'B<"row datatable-length-filter-cntr"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>rt<"row mt-3"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>';
    };

    static destroyDataTable = (tableElement) => {
        if (tableElement) {
            try {
                tableElement?.DataTable()?.destroy();
                tableElement = null;
            }
            catch (e) {
                console.log('Error Destroying', e);
            }
        }
    };
    
    static decodeHtml = (html) => {
        return $('<div>').html(html).text();
    }
}
