import jwt from 'jsonwebtoken';
import * as moment from 'moment';
import { getAccessToken, destroyToken } from '../Interceptors/Token';

export const guarded = () => {
  const token = jwt.decode(getAccessToken());

  if (getAccessToken() && token) {
    if (moment().isAfter(moment(token.exp * 1000))) {
      destroyToken();
      return false;
    }
    return true;
  }

  destroyToken();
  return false;
};

export const hasAnyAuthorities = (authorities) => {
  const loggedInUser = jwt.decode(getAccessToken());

  let role = false;

  if (!Array.isArray(authorities))
    authorities = [authorities];

  if (authorities && loggedInUser?.user?.authList) {
    role = loggedInUser.user.authList.find((auth) => authorities.includes(auth));
  }

  return !!role;
};

export const checkAuthorities = (roles, user) => {
  if (roles?.length && user?.authorities) {
    const roleFound = user.authorities.find(({ authName }) => roles.includes(authName));

    return !!roleFound;
  }

  return false;
};

export default guarded;
