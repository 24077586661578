import React from 'react';
import { Component } from '../../internal';

export class Message extends Component {

  buildMessages = (messages) => {
    if(!Array.isArray(messages)) {
      return (
        <div className="alert bgc-danger-l3 brc-danger-l1 text-danger mb-0" role="alert">
          <i className="fa fa-info-circle mr-1 text-danger"></i> {messages?.message}
        </div>
      );
    }
    else {
      return (
        <>
        {
          messages?.map((message, i) => {
            console.log(message);
            if(typeof message?.message == 'object')
              message = message.message;
            console.log(message);
            return (
              message?.type == 'E'
              ? (
                <div className="alert bgc-danger-l3 brc-danger-l1 text-danger mb-0" role="alert" key={`message-${i}`}>
                  <i className="fa fa-info-circle mr-1 text-danger"></i> {message?.message || message?.value || message}
                </div>
              )
              : (
                message?.type == 'W'
                ? (
                  <div className="alert bgc-warning-l3 brc-warning-l1 text-brown mb-0" role="alert" key={`message-${i}`}>
                    <i className="fa fa-info-circle mr-1 text-warning"></i> {message?.message || message?.value || message}
                  </div>
                )
                : (
                  <div className="alert bgc-blue-l3 brc-blue-l1 text-primary mb-0" role="alert" key={`message-${i}`}>
                    <i className="fa fa-info-circle mr-1 text-blue"></i> {message?.message || message?.value || message}
                  </div>
                )
              )
            )
          })
        }
        </>
      );
    }
  };

  draw() {
    return this.buildMessages(this.props.messages);
  };
}
