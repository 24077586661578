import ApiService from "../../Services/ApiService";

export default class EntityService {

  getDefinitions = () => {
    const url = '/entityDefinitions';
    return new ApiService().get(url);
  };

  getDefinitionData = (id, params) => {
    const paramsQs = typeof(params)=='string' ? params : encodeURIComponent(JSON.stringify(params || {}));
    const url = '/entityData/' + id + '?params=' + paramsQs;
    return new ApiService().get(url);
  };

  getDefinitionDataByUrl = (url, params) => {
    const paramsQs = typeof(params)=='string' ? params : encodeURIComponent(JSON.stringify(params));
    url += (url?.indexOf('?') > -1) ? '&params=' : '?params=' + paramsQs;
    const baseUrl = (url?.indexOf('://') > -1) ? url : null;
    const serviceEndPoint = (url?.indexOf('://') > -1) ? '' : url;
    return new ApiService(baseUrl).get(serviceEndPoint);
  };

  postData = (url, data, config) => {
    const baseUrl = (url?.indexOf('://') > -1) ? url : null;
    const serviceEndPoint = (url?.indexOf('://') > -1) ? '' : url;
    return new ApiService(baseUrl).post(serviceEndPoint, data, config);
  };

  delete = (url) => {
    const baseUrl = (url?.indexOf('://') > -1) ? url : null;
    const serviceEndPoint = (url?.indexOf('://') > -1) ? '' : url;
    return new ApiService(baseUrl).delete(serviceEndPoint);
  };
}
