import Navbar from "./Navbar";

const MainContent = (props) => {
  return (
    <div className="main-content">
      <div className="page-content">{props.children}</div>
      <div className="footer-tools">
        <a href="#" className="btn-scroll-up btn btn-white brc-black-tp7 px-25 py-2 text-95 border-2 radius-round mb-2 mr-2">
          <i className="fa fa-angle-double-up w-2 h-2"></i>
        </a>
      </div>
    </div>
  );
};

export default MainContent;
