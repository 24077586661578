import React from 'react';
import { Component } from '../../internal';

export class Container extends Component {  

  draw() {
    //console.log('Page.draw');
    const component = this.props.component;
    const data = this.props.data;
    return ( 
      <section className={component?.properties?.cssClassName || ''}>
        { this.renderComponents(component?.elements, data) }
      </section>
    );
  };
}
