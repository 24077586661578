import React from 'react';
import { Component } from '../../internal';

export class Steps extends Component {  

  buildSteps = (component) => {
    return (
      <ul class="nav flex-md-column flex-row justify-content-between">
        <li>test</li>
      {
        component?.elements?.map((step, i) => {
          <li class="nav-item" key={`${component.code}-${i}`}><a href={`#${step.code}`} class="nav-link active pl-0">{step?.properties?.label}</a></li>
        })
      }
      </ul>
    )
  };

  draw() {
    return this.buildSteps(this.props.component);
  };
}
