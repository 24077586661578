import ApiService from "../../Services/ApiService";

export default class ViewDefinitionService {

  getDefinitions = () => {
    const url = "/viewDefinitions";
    return new ApiService().get(url);
  };

  getDefinition = (id) => {
    const url = "/viewDefinitions/"+id;
    return new ApiService().get(url);
  };

  getView = (id, params) => {
    const paramsQs = typeof(params)=='string' ? params : encodeURIComponent(JSON.stringify(params));
    const url = '/views/' + id + '?params=' + paramsQs;
    return new ApiService().get(url);
  };

  saveViewData = (url, data, update) => {
    const baseUrl = (url?.indexOf('://') > -1) ? url : null;
    const serviceEndPoint = (url?.indexOf('://') > -1) ? '' : url;
    if(data._id || update)
      return new ApiService(baseUrl).put(serviceEndPoint, data);
    else
      return new ApiService(baseUrl).post(serviceEndPoint, data);
  };

  postViewData = (url, data) => {
    const baseUrl = (url?.indexOf('://') > -1) ? url : null;
    const serviceEndPoint = (url?.indexOf('://') > -1) ? '' : url;
    return new ApiService(baseUrl).post(serviceEndPoint, data);
  };

  getViewByUrl = (url) => {
    const baseUrl = (url?.indexOf('://') > -1) ? url : null;
    const serviceEndPoint = (url?.indexOf('://') > -1) ? '' : url;
    return new ApiService(baseUrl).get(serviceEndPoint);
  };
}
