import React from 'react';
import qs from "querystring";
import { Component } from '../../internal';
import ViewService from '../../../Services/ViewService';
import PageLoader from '../../../../Common/PageLoader';
import { Message } from '../common/Message';
import ServiceUtil from '../../../../Utils/ServiceUtil';

export class View extends Component {

  state = {
    messages: {},
    view: {},
    viewInitial: {},
    params: {},
    loading: false
  };

  componentDidMount() {
    this.loadView();
  };

  loadView = () => {
    const qsParams = qs.parse(window.location.search.split('?')[1]);
    const id = this.props.id || qsParams.id;
    const params = this.props.params || qsParams.params || '{}';
    if (id) {
      if (typeof (params) == 'string')
        this.getView(id, JSON.parse(decodeURIComponent(params)));
      else
        this.getView(id, params);
    }
  };

  refresh = () => {
    this.loadView();
  };

  filter(f) {
    if (typeof(f) == 'function') {
      const viewInitial = JSON.parse(JSON.stringify(this.state.viewInitial));
      const view = f(viewInitial);
      if(view != this.state.view)
        this.setState({ view });
    }
  };

  getView = async (id, params) => {
    //console.log(id, params);
    this.setState({ loading: true });
    try {
      const response = await new ViewService().getView(id, params);
      const view = response.data;
      const viewInitial = { ...view };
      const errorMessages = ServiceUtil.hasError(view?.data) ? view.data.messages : (view?.data?.error ? [view.data.error] : []);
      if (errorMessages.length > 0)
        this.setState({ loading: false, messages: errorMessages });
      else
        this.setState({ loading: false, messages: [], view, viewInitial, params }, () => {
          if (this.props && this.props.listeners && this.props.listeners.onNullFormCheck)
            this.props.listeners.onNullFormCheck(params, view);
        });
      
      if(typeof(this.props?.listeners?.onGetView) == 'function')
        this.props.listeners.onGetView(view);
    }
    catch (e) {
      this.setState({ loading: false, messages: [{ type: 'E', message: e?.response?.data?.error || 'Error occured (View)' }] });
    }
  };

  shouldComponentUpdate = async (nextProps, nextState) => {
    //console.log(this.props.id+' : ', nextProps.id, this.props.id != nextProps.id, (this.props.params && nextProps.params && JSON.stringify(this.props.params) !== JSON.stringify(nextProps.params)))
    if (this.props.id != nextProps.id || (this.props.params && nextProps.params && JSON.stringify(this.props.params) !== JSON.stringify(nextProps.params))) {
      //if(this.props.id != nextProps.id) {
      const id = nextProps.id;
      const params = nextProps.params;
      if (typeof (params) == 'string')
        await this.getView(id, JSON.parse(decodeURIComponent(params)));
      else
        await this.getView(id, params);
      return true;
    }
    else
      return false;
  };

  draw() {
    if (this.state.loading)
      return <PageLoader />;
    else if (this.state.messages?.length)
      return <Message messages={this.state.messages} />
    else
      return <Component components={this.state.view} params={this.state.params} listeners={this.props.listeners} options={this.props.options} formType={this.props.formType} ref={ref => { this.componentRef = ref; }} />;
  };
}