import jwt from 'jsonwebtoken';
import axios from 'axios';
import interceptor from './Interceptor';
import AppStateManager from '../../../AppStateManager';
import SystemService from '../../PortalPages/Services/SystemService';
//import { setUser } from '../store/reducers/user.slice';

const STORAGE_KEY = 'angle-store';

export const setAccessToken = (token, updateUserConfig) => {

  localStorage.removeItem('access_token');

  localStorage.setItem('access_token', token);

  if (updateUserConfig) {
    localStorage.removeItem('user_config');
    const token = jwt.decode(getAccessToken());
    if (token && token.user && token.user.userConfig)
      setUserConfig(token.user.userConfig);
    else
      setUserConfig([]);
  }

};

export const getUserConfig = () => {
  const userConfig = localStorage.getItem('user_config');
  if (userConfig)
    return JSON.parse(userConfig);
  else
    return null;
};

export const setUserConfig = (userConfig) => {
  return localStorage.setItem('user_config', JSON.stringify(userConfig));
};


export const getSystemConfig = (preview=false) => {
  const systemConfig = preview ? localStorage.getItem('system_config_preview') : localStorage.getItem('system_config');
  if (systemConfig) {
    const systemConfigObject = JSON.parse(systemConfig);
    const systemConfigBypass = localStorage.getItem('system_config_bypass');
    if(systemConfigBypass)
      systemConfigObject.enableRedirect = false;
    return systemConfigObject;
  }
  else
    return null;
};

export const setSystemConfig = (systemConfig, preview=false) => {
  if(preview)
    return localStorage.setItem('system_config_preview', JSON.stringify(systemConfig));
  else {
    if(window.location.search?.includes('bypassRedirect'))
      localStorage.setItem('system_config_bypass', true);
    AppStateManager.setSystem(systemConfig); // used to toggle icon in NavBar
    return localStorage.setItem('system_config', JSON.stringify(systemConfig));
  }
};


export const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

/*export const setRefreshToken = (token) => {
  localStorage.setItem("refresh_token", token);
}

export const getRefreshToken = (token) => {
  return localStorage.getItem("refresh_token");
}*/
/*
export const setUserFromAccessToken = () => (dispatch) => {
  try {
    const { user } = jwt.decode(getAccessToken());
    dispatch(setUser(user));
    return user;
  } catch (err) {
    console.log("Can't read token payload", err);
    return null;
  }
};
*/

export const samlLogin = (tt) =>
  axios.post(process.env.REACT_APP_PORTAL_API_URL + '/auth/exchange', {
    tt,
  })
    .then((res) => {
      if (res?.data?.access_token) {
        setAccessToken(res.data.access_token, true);
      }

      return res;
    })
    .catch((err) => console.log(err));

export const logout = () => {
  const accessRoute = localStorage.getItem("access_route");
  destroyToken();
  //localStorage.removeItem('access_token');
  //localStorage.removeItem('user_config');
  //localStorage.removeItem('access_route');

  if (accessRoute && accessRoute == "login")
    window.location = process.env.REACT_APP_CONTEXT_ROOT + '/login';
  else
    window.location.href = process.env.REACT_APP_SSO_LOGOUT_URL;
};

export const proxyToken = (userId) =>
  interceptor
    .get(process.env.REACT_APP_PORTAL_API_URL + "/auth/proxy/" + userId/*, { refresh_token: ""getRefreshToken() }*/)
    .then((res) => {
      // eslint-disable-next-line camelcase
      if (res?.data?.access_token) {
        setAccessToken(res.data.access_token, false);
        return res.data.access_token;
      }
      /*if (res?.data?.refresh_token) {
        setRefreshToken(res.data.refresh_token);
      }*/
    });

export const refreshToken = () =>
  interceptor
    .post(process.env.REACT_APP_PORTAL_API_URL + "/auth/refresh"/*, { refresh_token: ""getRefreshToken() }*/)
    .then((res) => {
      // eslint-disable-next-line camelcase
      if (res?.data?.access_token) {
        setAccessToken(res.data.access_token, false);
        return res.data.access_token;
      }
      /*if (res?.data?.refresh_token) {
        setRefreshToken(res.data.refresh_token);
      }*/
    });

export const destroyToken = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user_config');
  localStorage.removeItem('system_config');
  localStorage.removeItem('system_config_bypass')
  localStorage.removeItem('access_route');
  //setRefreshToken("");
}

export const goToLogin = async () => {
    const accessRoute = localStorage.getItem("access_route");
    destroyToken();
    if (accessRoute && accessRoute == "login" || window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
      window.location = process.env.REACT_APP_CONTEXT_ROOT + '/login';
    else {
      await (new SystemService()).get();
      const system = getSystemConfig();
      if(!system?.enableRedirect)
        window.location = process.env.REACT_APP_SSO_LOGIN_URL + '&TargetResource=' + encodeURIComponent(window.location.href);
      else
        window.location = process.env.REACT_APP_CONTEXT_ROOT + '/systemredirect';
    }
}