export default class LogUtil {
    static debug = (msg, callerClass) => {
        if (process.env.REACT_APP_LOG_LEVEL == "debug") {
            console.log(msg, callerClass);
        }
    }

    static warn = (msg, callerClass) => {
        if (process.env.REACT_APP_LOG_LEVEL == "debug" || process.env.REACT_APP_LOG_LEVEL == "warn") {
            console.warn(msg, callerClass);
        }
    }

    static error = (msg, callerClass) => {
        if (process.env.REACT_APP_LOG_LEVEL == "debug" || process.env.REACT_APP_LOG_LEVEL == "warn" || process.env.REACT_APP_LOG_LEVEL == "error") {
            console.error(msg, callerClass);
        }
    }
}