import React from "react";
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { withNamespaces, Trans } from "react-i18next";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import jwt from 'jsonwebtoken';
import * as actions from "../../store/actions/actions";

import { destroyToken } from '../Services/Interceptors/Token';

class Proxy extends React.Component {


  clearProxy = async () => {
    await destroyToken();
    window.location = process.env.REACT_APP_CONTEXT_ROOT + '/hrd/home';
  };

  render() {
    const { t } = this.props;
    const token = jwt.decode(localStorage.getItem('access_token'));
    return (
      token?.user?.proxy ?

        <div class="alert d-flex bgc-red-l3 brc-success-m4 border-0 p-0" role="alert">
          <div class="bgc-red px-3 py-1 text-center radius-l-1">
            <span class="fa-2x text-white">
              &#9888;
            </span>
          </div>

          <span class="ml-3 align-self-center text-dark-tp3 text-110">
            {t("proxyMessage")}
          </span>

          <div className="align-self-center ml-3 float-right">
            <div class="btn-group">

              <button className="btn btn-danger px-25 mr-2" type="button" onClick={this.clearProxy}>
                <i className="fa fa-close"></i>
              </button>
            </div>
          </div>
        </div>

        :
        ''

    );
  }
}

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default compose(withNamespaces("common"), connect(mapStateToProps, mapDispatchToProps))(withRouter(Proxy));
