import Sidebar from './Sidebar';
import MainContainer from './MainContainer';
import MainContent from './MainContent';
import Proxy from "./Proxy";

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const BasePage = props => (
    <MainContainer>
        <ToastContainer />
        <Sidebar />
        <MainContent>
            <Proxy />
            {props.children}
        </MainContent>
    </MainContainer>
)

export default BasePage;
