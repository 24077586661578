import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';


import Menu from '../../Menu.js';
import MenuService from '../PortalPages/Services/MenuService';
import i18n from 'i18next';
import { getUserConfig } from '../Services/Interceptors/Token';
import AppStateManager from '../../AppStateManager';

const SidebarItem = ({ item, isActive }) => (
  <li className={isActive ? 'nav-item active' : 'nav-item'} onClick={() => { AppStateManager.clear() }}>
    <Link to={process.env.REACT_APP_CONTEXT_ROOT + item.link} className="nav-link">
      <i className={"nav-icon " + item.icon}></i>
      <span className="nav-text fadeable text-110">
        <span><Trans i18nKey={item.translate}>{item.text}</Trans></span>
      </span>
    </Link>
    <b className="sub-arrow"></b>
  </li>
);

class Sidebar extends Component {

  state = {
    menu: { menuItems: [] }
  };

  /** map menu config to string to determine which element to render */
  itemType = item => {
    if (item.heading) return 'heading';
    if (!item.submenu) return 'menu';
    if (item.submenu) return 'submenu';
  };

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
  };

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };
  componentDidMount() {

    //set the language
    const userConfig = getUserConfig();
    const lang = userConfig?.filter((config) =>
      config.key == "language"
    );

    if (lang?.length)
      this.changeLanguage(lang[0].value);

    this.getMenu();
    // Listen for routes changes in order to hide the sidebar on mobile
    this.props.history.listen(this.closeSidebar);
  };

  getMenu = async () => {
    const response = await new MenuService().get();
    this.setState({ menu: response?.data || { menuItems: [] } });
  };

  closeSidebar = () => {
    $('.sidebar').aceSidebar('hide');
    this.props.actions.changeSetting('sidebar-visible', false);
  };

  render() {
    return (
      <div id="sidebar" className="sidebar sidebar-fixed hoverable sidebar-light collapsed" data-swipe="true" data-dismiss="true">
        <div className="sidebar-inner">
          <div className="ace-scroll flex-grow-1" data-ace-scroll="{}">
            <nav aria-label="Main">
              <ul className="nav has-active-border active-on-right">
                {
                  this.state.menu.menuItems.map((item, i) => {
                    if (this.itemType(item) === 'menu')
                      return <SidebarItem isActive={this.routeActive(item.link)} item={item} key={item.link + i} />
                    return null; // unrecognized item
                  })
                }
              </ul>
            </nav>
          </div>
        </div>
      </div>
    )
  }
};

Sidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces('menu')(withRouter(Sidebar)));
