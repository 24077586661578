import React from 'react';
import DataUtil from '../../../../Utils/DataUtil';
import { Component } from '../../internal';

export class Checkbox extends Component {
  
  draw() {
    const component = this.props.component;
    const properties = component?.properties || {};
    const name = component.code;
    const label = properties?.value;
    const data = this.props.data;
    const value = DataUtil.fillTemplate(label, data);

    return React.createElement('input', { type: 'checkbox', name, value, data: JSON.stringify(data), properties: JSON.stringify(properties) });
  };
}
