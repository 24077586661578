import AppState from "react-app-state";

class AppStateManager extends AppState {
  constructor() {
    super({
      form: null,
      search: null,
      inbox: null,
      formStatus: null,
      jobSearch: null,
      jobCatalog: null,
      reports: null,
      report: null,
      system: null
    })
  };

  clear() {
    super.set({ reports: null, inbox: null, formStatus: null });
  };

  setForm(form) {
    super.set({ form });
  };

  getForm() {
    return super.get('form');
  };

  setSearch(search) {
    super.set({ search });
  };

  getSearch() {
    return super.get('search');
  };

  setInbox(inbox) {
    super.set({ inbox });
  };

  getInbox() {
    return super.get('inbox');
  };

  setFormStatus(formStatus) {
    super.set({ formStatus });
  };

  getFormStatus() {
    return super.get('formStatus');
  };

  setJobSearch(jobSearch) {
    super.set({ jobSearch });
  };

  getJobSearch() {
    return super.get('jobSearch');
  };

  setJobCatalog(jobCatalog) {
    super.set({ jobCatalog });
  };

  getJobCatalog() {
    return super.get('jobCatalog');
  };

  setReports(reports) {
    super.set({ reports });
  };

  getReports() {
    return super.get('reports');
  };

  setReport(report) {
    super.set({ report });
  };

  getReport() {
    return super.get('report');
  };

  setSystem(system) {
    super.set({ system });
  };

  getSystem() {
    return super.get('system');
  };
}

export default new AppStateManager();