import React from 'react';
import DataUtil from '../../../../Utils/DataUtil';
import { Component } from '../../internal';

export class Link extends Component {

  draw() {
    const listeners = this.props.listeners;
    const component = this.props.component;
    const key = component?.properties?.text;
    let href = component?.properties?.href;
    const includeRowAsParams = component?.properties?.includeRowAsParams;
    const className = component?.properties?.cssClassName;
    const data = this.props.data;
    const attributes = {};
    let value = '';

    try {
      value = DataUtil.fillTemplate(key, data);
      href = DataUtil.fillTemplate(href, data);
      if(includeRowAsParams) {
        const params = JSON.stringify(data);
        if(href.includes('?'))
          href = `${href}&params=${params}`
        else
          href = `${href}?params=${params}`
      }
    } catch (e) { }

    if (className)
      attributes.className = className;

    if (typeof listeners?.onLinkClick == 'function')
      return React.createElement('a', { ...attributes, onClick: (e) => { listeners.onLinkClick(this, data, component, e) } }, value);
    else
      return React.createElement('a', { ...attributes, href }, value);
  };
}