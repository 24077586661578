import interceptor from "./Interceptors/Interceptor";

export default class ApiService {

  constructor(baseURL) {
    if(baseURL === null || baseURL === undefined)
      baseURL = process.env.REACT_APP_API_URL;
    this.baseURL = baseURL;
  };

  get = (url, config) => {
    return interceptor.get(this.baseURL + url, config);
  };

  post = (url, data, config) => {
    return interceptor.post(this.baseURL + url, data, config);
  };

  put = (url, data, config) => {
    return interceptor.put(this.baseURL + url, data, config);
  };

  delete = (url, config) => {
    return interceptor.delete(this.baseURL + url, config);
  };
}