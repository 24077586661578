import React from 'react';
import DataUtil from '../../../Utils/DataUtil';
import { Page, Container, Steps, Search, Columns, Column, View, Table, List, Form, SAPForm, Label, Link, Button, Radio, Checkbox, Options, Image } from '../internal';

export class Component extends React.Component {

  renderComponents = (components, data, index) => {
    if (components) {
      if (!Array.isArray(components))
        components = [components];
      return components.map(component => this.renderComponent(component, data, index));
    }
  };

  renderComponent = (component, data, index) => {
    const type = component?.type;
    switch (type) {
      case 'PAGE':
        return <Page component={component} data={data} key={DataUtil.generateKey(type)} formType={this.props.formType} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'CONTAINER':
        return <Container component={component} data={data} key={DataUtil.generateKey(type)} formType={this.props.formType} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'STEPS':
        return <Steps component={component} data={data} key={DataUtil.generateKey(type)} formType={this.props.formType} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'SEARCH':
        return <Search component={component} data={data} key={DataUtil.generateKey(type)} formType={this.props.formType} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'COLUMNS':
        return <Columns component={component} data={data} key={DataUtil.generateKey(type)} formType={this.props.formType} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'COLUMN':
        return <Column component={component} data={data} key={DataUtil.generateKey(type)} formType={this.props.formType} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'VIEW':
        return <View component={component} data={data} key={DataUtil.generateKey(type)} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'TABLE':
        return <Table component={component} data={data} key={DataUtil.generateKey(type)} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'LIST':
        return <List component={component} data={data} key={DataUtil.generateKey(type)} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'FORM':
        return <Form component={component} data={data} key={DataUtil.generateKey(type)} formType={this.props.formType} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'SAPFORM':
        return <SAPForm component={component} data={data} key={DataUtil.generateKey(type)} formType={this.props.formType} params={this.props.params} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'LABEL':
        return <Label component={component} data={data} key={DataUtil.generateKey(type)} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'LINK':
        return <Link component={component} data={data} key={DataUtil.generateKey(type)} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'BUTTON':
        return <Button component={component} data={data} key={DataUtil.generateKey(type)} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'RADIO':
        return <Radio component={component} data={data} key={DataUtil.generateKey(type)} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'CHECKBOX':
        return <Checkbox component={component} data={data} key={DataUtil.generateKey(type)} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'OPTIONS':
        return <Options component={component} data={data} key={DataUtil.generateKey(type)} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }} />;
      case 'IMAGE':
        const properties = component?.properties;
        const key = properties?.value;
        let value = '';
        try { value = DataUtil.fillTemplate(key, data); } catch (e) { }

        return <Image component={component} data={data} key={DataUtil.generateKey(type)} listeners={this.props.listeners} options={this.props.options} ref={ref => { this.componentRef = ref; }}>
          <div className="align-items-center pt-25 mx-2px border-2 w-8 h-8 radius-round bgc-purple text-white d-inline-block">
            <h2 className="w-100 text-center">{DataUtil.getInitials(value)}</h2>
          </div>
        </Image>;
      default:
        return null;//<div key={ DataUtil.generateKey(type) }>Component not found ({type})</div>;
    }
  };

  draw(components) {
    return this.renderComponents(components, components?.data);
  };

  render() {
    const r = this.draw(this.props.components);
    if (this.props?.listeners?.postRender)
      this.props.listeners.postRender(this);
    return r;
  };
}
