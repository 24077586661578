import logo from '../../assets/image/nbcu-logo.png';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const BaseBlankPage = props =>(
    <div className="body-container dark-bg">
        <div className="main-container container bgc-transparent">
            <div className="main-content minh-100 justify-content-center">
                <div className="text-center">
                    <img src={logo} className="login-logo" />
                </div>
                <div className="p-3 p-md-3">
                    <div className="row" id="row-1">
                        <div className="col-12 col-md-6 offset-md-3 bgc-white shadow radius-1 overflow-hidden">
                            { props.children }
                        </div>
                    </div>
                    <div className="d-lg-none my-3 text-white-tp1 text-center">
                        <img src={logo} height="24px" /> Worklogix &copy; 2021
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
)

export default BaseBlankPage;
