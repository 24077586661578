import React from 'react';
import ViewDefinitionService from "../../../Services/ViewDefinitionService";
import LogUtil from "../../../../Utils/LogUtil";
import { Component, FormRenderer, FormioRenderer } from '../../internal';
import Loader from "../../../../Common/Loader";

export class Form extends Component {

  state = {
    loading: false,
    component: null,
    data: null
  };

  componentDidMount() {
    this.setState({ component: this.props.component }, this.getData);
  };

  getData = async () => {
    if(this.state.component?.data)
      this.setState({ data: this.state.component?.data });
    else if(this.state.component?.properties?.serviceUrl) {
      this.setState({ loading: true });
      const response = await new ViewDefinitionService().getViewByUrl(this.state.component?.properties?.serviceUrl).catch((error) => { LogUtil.error(error); });
      this.setState({ loading: false, data: response.data });
    }
  };

  preEvent = ({event, data, params, component}) => {
    console.log('Form - preEvent()', event, data, params);
    return true;
  };

  preButtonValidationEvent = ({event, data, params, formValid, formio}) => {
    console.log('Form - preButtonValidationEvent()', event, data, params);
    return true;
  };

  preButtonEvent = (event, data, params) => {
    console.log('Form - preButtonEvent()', event, data, params);
    return true;
  };

  postButtonEvent = (event, data, params, response) => {
    console.log('Form - postButtonEvent()', event, data, params, response);
    return true;
  };

  draw() {
    if(this.props?.formType == 'formio')
      return this.state.loading ? <Loader /> : <FormioRenderer component={this.props.component} preEvent={this.preEvent} preButtonValidationEvent={this.preButtonValidationEvent} preButtonEvent={this.preButtonEvent} postButtonEvent={this.postButtonEvent} />;
    else
      return this.state.loading ? <Loader /> : <FormRenderer component={this.props.component} preEvent={this.preEvent} preButtonValidationEvent={this.preButtonValidationEvent} preButtonEvent={this.preButtonEvent} postButtonEvent={this.postButtonEvent} />;
  };
};
