import React from 'react';
import DataUtil from '../../../../Utils/DataUtil';
import { Component } from '../../internal';

export class Button extends Component {
  
  draw() {
    let label = this.props.component?.properties?.label;
    let data = this.props.data;
    let value = DataUtil.fillTemplate(label, data);
    
    return React.createElement('button', null, value);
  };
}
