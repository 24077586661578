import React from 'react';
import DataUtil from '../../../../Utils/DataUtil';
import { Component } from '../../internal';


export class Image extends Component {

  state = {
    showInitials: false
  };

  onError = (e) => {
    const properties = this.props.component?.properties;
    //console.log("Properties", properties);

    e.target.onerror = null;
    if (properties?.fallback == 'INITIALS')
      this.setState({ showInitials: true });
    else
      e.target.src = properties?.fallback || '';
  };

  draw() {

    if (this.state.showInitials) {
      return (
        this.props.children
      );
    }
    else {

      const properties = this.props.component?.properties;
      const data = this.props.data;

      const key = properties?.imageUrl;
      //console.log("Key", key);

      let value = '';

      try { value = DataUtil.fillTemplate(key, data); } catch (e) { console.log(e) }
      //console.log("Data", data, value);

      if (value == null || value == '')
        value = "onerror"; //force the on error to happen

      return <img crossorigin_disabled="true" onError={this.props.options?.image?.onError ? (e) => this.props.options?.image?.onError(e, this) : this.onError} className={properties?.cssClassName || ''} src={value} />;
    }
  };
}
