import React from 'react';
import { withRouter } from 'react-router-dom';
import { Component } from '../../internal';

class PageClass extends Component {

  back = () => {
    this.props.history.goBack();
  };

  draw() {
    //console.log('Page.draw');
    const component = this.props.component;
    const data = this.props.data;
    return ( 
      <section>
        {
          component?.properties?.title && component.properties?.title?.trim() != ''
          ? (
            <div className="page-header mb-4 border-b-1 brc-grey-l2">
              <h1 className="page-title text-primary-d2 text-150">
              { component.properties.action ? <span><i className={component.properties.icon} onClick={this.back}></i> </span> : null }
              { component.properties.title } 
              {
                component.properties?.subTitle && component.properties?.subTitle?.trim() != ''
                ? <small className="page-info text-secondary-d2 text-nowrap"> <i className="fa fa-angle-double-right text-80"></i> { component.properties.subTitle }</small>
                : ''
              }
              </h1>
            </div>
          )
          : ''
        }
        {
          component?.description
          ? (
            <div>
              <hr />
              <div className="alert bgc-primary-l4 brc-primary-m4 d-flex align-items-center text-dark-tp2" role="alert">
                <i className="fas fa-info-circle mr-3 fa-2x text-blue"></i>
                <div>{ component?.description }</div>
              </div>
            </div>
          )
          : ''
        }
        { this.renderComponents(component?.elements, data) }    
      </section>
    );
  };
}

export const Page = withRouter(PageClass);