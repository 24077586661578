import ApiService from "../../Services/ApiService";
import { setSystemConfig } from "../../Services/Interceptors/Token";

export default class SystemService {

  get = () => {
    let url = "/systemredirect/get";
    return new ApiService(process.env.REACT_APP_PORTAL_API_URL).get(url)
    .then((response) => {
      console.log('SystemService.get().done()', response?.data)
      setSystemConfig(response?.data || null);
      return response;
    });
  };

  create = (system) => {
    const url = "/systemredirect/create";
    return new ApiService(process.env.REACT_APP_PORTAL_API_URL).post(url, system);
  };
}
